import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    padding: 4px 16px;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const WishlistTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.TERTIARYCOLORDARK};
`
export const WishlistSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  &.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const NoDataWishlistSection = styled(WishlistSection)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WishlistProductSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const WishlistCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 15px;
  width: 100%;
  max-width: 175px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  position: relative;
  cursor: pointer;
`
export const OptionSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const WishlistIconSection = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
`
export const ProductImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 120px;
    object-fit: contain;
  }
`
export const ProductName = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${theme.TERTIARYTEXTCOLOR};
  max-width: 175px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 175px;
  button {
    text-transform: none;
  }
`
export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
