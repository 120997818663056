import React, { useContext, useState } from 'react'
import ReactGA from 'react-ga4'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { Button as NewButton } from '@mui/material'

import { Link, useHistory } from 'react-router-dom'
import Button from '../../shared/button/button'
import ErrorMessage from '../../shared/error-message/errorMessage'
import Input from '../../shared/input/input'
import PasswordInput from '../../shared/passwordInput/input'

import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getErrorMessage } from '../../../api/utils/mapFirebaseError'
import { AddCookie } from '../../../utils/cookies'
import { ToastContext } from '../../../context/toastContext'
import Google_Logo from '../../../assets/images/google.png'
import {
  PageWrapper,
  ImageContainer,
  LogoContainer,
  FormContainer,
  Title,
  FormWrapper,
  // OrText,
  GoogleText,
  SignUpText,
  AccountText,
  NavigationLinks,
  ButtonContainer,
  ButtonText,
} from '../../../styles/auth/Login'
import Background from '../../../assets/images/screenImage.png'
import Logo from '../../../assets/images/bharatham.png'

const SignUp = () => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const history = useHistory()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [signUpUsingGoogleloading, setSignUpUsingGoogleLoading] = useState(false)
  const [signUpUsingEmailAndPasswordloading, setSignUpUsingEmailAndPasswordLoading] = useState(false)
  const dispatch = useContext(ToastContext)
  const [inlineError, setInlineError] = useState({
    name_error: '',
    email_error: '',
    password_error: '',
  })
  // use this function to check the name
  function checkName() {
    if (!name) {
      setInlineError((inlineError) => ({
        ...inlineError,
        name_error: 'Full Name cannot be empty',
      }))
      return false
    }

    return true
  }

  // use this function to check the email
  function checkEmail() {
    if (!email) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: 'Email cannot be empty',
      }))
      return false
    }

    return true
  }

  function checkPassword() {
    if (!password) {
      setInlineError((inlineError) => ({
        ...inlineError,
        password_error: 'Password cannot be empty',
      }))
      return false
    } else if (password && password?.length < 8) {
      setInlineError((inlineError) => ({
        ...inlineError,
        password_error: 'Password cannot be less than 8 characters',
      }))
      return false
    }

    return true
  }

  function handleSignUpWithEmailAndPassword(e) {
    e.preventDefault()
    const allCheckPassed = [checkName(), checkEmail(), checkPassword()].every(Boolean)
    if (!allCheckPassed) {
      return
    }

    setSignUpUsingEmailAndPasswordLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        const { user } = result
        updateProfile(user, { displayName: name.trim() })
          .then(() => {
            handleRedirect(user)
          })
          .catch((error) => {
            const errorCode = error.code
            const errorMessage = getErrorMessage(errorCode)
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: errorMessage,
              },
            })
          })
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = getErrorMessage(errorCode)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errorMessage,
          },
        })
      })
      .finally(() => setSignUpUsingEmailAndPasswordLoading(false))
  }

  function handleSignUpWithGoogle() {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Signup with google',
    })

    setSignUpUsingGoogleLoading(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        handleRedirect(result.user)
      })
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          const errorMessage = error.message
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: errorMessage,
            },
          })
        }
      })
      .finally(() => setSignUpUsingGoogleLoading(false))
  }

  function handleRedirect(user) {
    const { displayName, email, photoURL, accessToken, uid } = user
    // AddCookie('token', accessToken)
    localStorage.setItem('token', accessToken)
    AddCookie('user', JSON.stringify({ name: displayName, id: uid, email, photoURL }))
    history.push('/')
  }

  return (
    <PageWrapper>
      <FormContainer>
        <LogoContainer>
          <Link to="/">
            <img src={Logo} alt="Bharatham" />
          </Link>
        </LogoContainer>
        <FormWrapper>
          <form onSubmit={handleSignUpWithEmailAndPassword}>
            <Title>SignUp</Title>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Enter Name"
              label_name="Full Name"
              autoComplete="off"
              has_error={inlineError.name_error}
              onChange={(event) => {
                setName(event.target.value.trim())
                setInlineError((inlineError) => ({
                  ...inlineError,
                  name_error: '',
                }))
              }}
              onBlur={checkName}
              required
            />
            {inlineError.name_error && <ErrorMessage>{inlineError.name_error}</ErrorMessage>}
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Email"
              label_name="Email"
              autoComplete="off"
              has_error={inlineError.email_error}
              onChange={(event) => {
                setEmail(event.target.value)
                setInlineError((inlineError) => ({
                  ...inlineError,
                  email_error: '',
                }))
              }}
              onBlur={checkEmail}
              required
            />
            {inlineError.email_error && <ErrorMessage>{inlineError.email_error}</ErrorMessage>}
            <PasswordInput
              id="password"
              name="password"
              // type="password"
              placeholder="Enter Password"
              label_name="Password"
              autoComplete="off"
              has_error={inlineError.password_error}
              onChange={(event) => {
                setPassword(event.target.value)
                setInlineError((inlineError) => ({
                  ...inlineError,
                  password_error: '',
                }))
              }}
              onBlur={checkPassword}
              required
            />
            {inlineError.password_error && <ErrorMessage>{inlineError.password_error}</ErrorMessage>}
            <ButtonContainer>
              <NewButton className="signup_button" variant="contained" type="submit">
                Signup
              </NewButton>
              <Button
                className="google_button"
                isloading={signUpUsingGoogleloading ? 1 : 0}
                disabled={signUpUsingGoogleloading || signUpUsingEmailAndPasswordloading}
                button_text={
                  <GoogleText style={{ display: 'flex', gap: '10px' }}>
                    <img src={Google_Logo} alt="logo" style={{ height: '20px' }} />
                    <ButtonText>Signup with Google</ButtonText>
                  </GoogleText>
                }
                onClick={handleSignUpWithGoogle}
              />
            </ButtonContainer>
            <NavigationLinks>
              <AccountText>Already have an account ?</AccountText>
              <Link to="/login">
                <SignUpText>Sign In</SignUpText>
              </Link>
            </NavigationLinks>
          </form>
        </FormWrapper>
      </FormContainer>
      <ImageContainer>
        <img src={Background} alt="carousel" />
      </ImageContainer>
    </PageWrapper>
  )
}

export default SignUp
