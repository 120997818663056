import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={73} height={73} fill="none" {...props}>
    <g fill="#734D00" clipPath="url(#a)">
      <path d="m72.623 41.791-3.645-5.576c-1.61-2.464-4.343-3.935-7.316-3.935h-9.425v-5.424a6.01 6.01 0 0 0-6.005-6.005H28.589c-.99-5.17-5.541-9.093-10.997-9.093-6.177 0-11.202 5.025-11.202 11.202 0 3.883 1.989 7.31 5 9.32H1.952a1.143 1.143 0 1 0 0 2.286H13.38v2.285H8.808a1.142 1.142 0 0 0 0 2.286h4.572v2.286H1.952a1.143 1.143 0 1 0 0 2.286H13.38v5.061c-2.015.89-3.429 2.9-3.429 5.24v1.127c0 .632.512 1.143 1.143 1.143h10.137c.65 2.797 3.157 4.89 6.149 4.89s5.499-2.093 6.149-4.89h21.988c.65 2.797 3.157 4.89 6.149 4.89 2.99 0 5.499-2.093 6.149-4.89h3.85c.632 0 1.143-.511 1.143-1.143v-12.72c0-.223-.065-.44-.185-.626ZM8.675 22.96c0-4.917 4-8.917 8.916-8.917 4.918 0 8.917 4 8.917 8.917 0 4.916-4 8.916-8.916 8.916-4.917 0-8.917-4-8.917-8.916Zm6.99 20.749h2.286a1.143 1.143 0 1 0 0-2.286h-2.285v-2.286h8a1.142 1.142 0 1 0 0-2.285h-8v-2.286h14.857a1.143 1.143 0 1 0 0-2.286h-6.729a11.196 11.196 0 0 0 4.991-9.143h17.448a3.723 3.723 0 0 1 3.719 3.719V48.28H15.68l-.014.001V43.71Zm34.287 10.285h-16.32a6.292 6.292 0 0 0-1.623-3.428h17.943v3.428ZM15.68 50.566h7.071a6.29 6.29 0 0 0-1.624 3.428h-8.89a3.448 3.448 0 0 1 3.443-3.428Zm11.7 8.318a4.037 4.037 0 0 1-4.032-4.033 4.038 4.038 0 0 1 4.032-4.032 4.037 4.037 0 0 1 4.032 4.032 4.037 4.037 0 0 1-4.032 4.033Zm34.286 0a4.037 4.037 0 0 1-4.033-4.033 4.038 4.038 0 0 1 4.033-4.032 4.037 4.037 0 0 1 4.032 4.032 4.037 4.037 0 0 1-4.032 4.033Zm8.857-4.89h-2.604c-.42-3.078-3.061-5.46-6.253-5.46s-5.832 2.382-6.253 5.46h-3.176V34.566h9.425c2.197 0 4.217 1.084 5.403 2.899l3.458 5.292v11.237Z" />
      <path d="M63.536 54.851a1.87 1.87 0 1 1-3.74 0 1.87 1.87 0 0 1 3.74 0ZM29.25 54.851a1.87 1.87 0 1 1-3.74 0 1.87 1.87 0 0 1 3.74 0ZM19.095 22.96v-5a1.142 1.142 0 1 0-2.286 0v4.627l-2.332 3.2a1.141 1.141 0 0 0 .922 1.816c.353 0 .7-.163.924-.47l2.553-3.5c.142-.195.219-.43.219-.673ZM61.28 35.708h-2.186c-.631 0-1.143.512-1.143 1.144v8c0 .631.512 1.142 1.143 1.142h7.692c.717 0 1.358-.37 1.718-.99.36-.619.36-1.36.006-1.978l-2.669-4.672a5.265 5.265 0 0 0-4.56-2.646Zm-1.043 8v-5.713h1.043c1.063 0 2.05.572 2.576 1.494l2.411 4.22h-6.03Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.809.528h72v72h-72z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
