import React, { useContext, useEffect, useState } from 'react'
import useStyles from './style'
import Products from './products/products'
import Outlets from './outlets/outlets'
import { getBrandDetailsRequest } from '../../api/brand.api'
import useCancellablePromise from '../../api/cancelRequest'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { ToastContext } from '../../context/toastContext'
import Loading from '../shared/loading/loading'

const Brand = ({ brandId }) => {
  const classes = useStyles()
  const dispatch = useContext(ToastContext)
  const [brandDetails, setBrandDetails] = useState(null)
  const [brandIsFromFAndBCategory, setBrandIsFromFAndBCategory] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const getBrandDetails = async () => {
    setIsLoading(true)
    try {
      const data = await cancellablePromise(getBrandDetailsRequest(brandId))
      if (data.domain === 'ONDC:RET11') {
        setBrandIsFromFAndBCategory(true)
      } else {
        setBrandIsFromFAndBCategory(false)
      }

      setBrandDetails(data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {

    if (brandId === 'Gift Boxes') {
      setBrandDetails({})
    }

    if (brandId !== 'Gift Boxes') {
      getBrandDetails()
    }
  }, [brandId])

  if (isLoading || brandDetails === null) {
    return (
      <div className={classes.loader}>
        <Loading />
      </div>
    )
  } else {
    if (brandIsFromFAndBCategory) {
      return <Outlets brandId={brandId} brandDetails={brandDetails} />
    } else {
      return (
        <>
          {/* <Categories brandId={brandId} brandDetails={brandDetails} /> */}
          <Products brandId={brandId} brandDetails={brandDetails} />
        </>
      )
    }
  }
}

export default Brand
