import moment from 'moment'
import { useHistory } from 'react-router-dom'
import NoDataFound from 'views/EmptyData'
import {
  AllBlogsSection,
  LatestBlogCardSection,
  LatestBlogImageSection,
  LatestBlogDetailSection,
  ContentSection,
  DateAndCategorySection,
  LatestBlogDateWrapper,
  LatestBlogCategoryWrapper,
  LatestBlogBlogTitle,
  LatestBlogReadMoreText,
  NoDataFoundWrapper,
} from 'styles/hpLayout/categoryBlogs'

const CategoryWiseBlogs = ({ data }) => {
  const history = useHistory()
  const handleClick = (id) => {
    history.push(`/blog/${id}`)
  }

  return (
    <AllBlogsSection>
      {data && data.length > 0 ? (
        data?.map((item, index) => (
          <LatestBlogCardSection key={index} onClick={() => handleClick(item?.id)}>
            <LatestBlogImageSection>
              <img src={item?.bannerImage} alt={item?.title} />
            </LatestBlogImageSection>
            <LatestBlogDetailSection>
              <ContentSection>
                <DateAndCategorySection>
                  <LatestBlogDateWrapper>{moment(item?.updatedAt).format('MMMM Do, YYYY')}</LatestBlogDateWrapper>
                  <LatestBlogCategoryWrapper>{item?.category}</LatestBlogCategoryWrapper>
                </DateAndCategorySection>
                <LatestBlogBlogTitle>{item?.title}</LatestBlogBlogTitle>
                <LatestBlogReadMoreText onClick={() => handleClick(item?.id)}>Read More</LatestBlogReadMoreText>
              </ContentSection>
            </LatestBlogDetailSection>
          </LatestBlogCardSection>
        ))
      ) : (
        <NoDataFoundWrapper>
          <NoDataFound />
        </NoDataFoundWrapper>
      )}
    </AllBlogsSection>
  )
}

export default CategoryWiseBlogs
