import React, { useState } from 'react'
import { categoryList } from '../../../constants/categories'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '../../../context/newThemeContext'
import ModalComponent from 'components/common/Modal'
import TrackOrderModal from 'views/TrackOrderModal'
import FacebookIcon from 'assets/svg/HPSvgs/FacebookIcon'
import InstagramIcon from 'assets/svg/HPSvgs/InstagramIcon'
import YouTubeIcon from 'assets/svg/HPSvgs/YouTubeIcon'
import EmailIcon from 'assets/svg/HPSvgs/EmailIcon'
import PhoneIcon from 'assets/svg/HPSvgs/PhoneIcon'
import {
  CopyRight,
  CopyRightSection,
  FooterContainer,
  FooterDiv,
  FooterHeading,
  FooterHeading1,
  FooterRightDiv,
  FooterWrapper,
  HeadingSection,
  SocialMediaIconWrapper,
  SocialMediaSectionTitle,
  SocialMediaIconsWrapper,
  FooterHeadingTitle,
  ContactHeading,
} from 'styles/hpLayout/footer'

const Footer = () => {
  const history = useHistory()
  const locationData = useLocation()
  const [trackOrderModal, setTrackOrderModal] = useState(false)

  // const useQuery = () => {
  //   const { search } = locationData
  //   return React.useMemo(() => new URLSearchParams(search), [search])
  // }
  // let query = useQuery()

  // const updateQueryParams = (catName) => {
  //   const params = new URLSearchParams(locationData.search)
  //   params.set('c', catName)

  //   if (locationData.search === '' && query.get('c') === null) {
  //     history.push(`/products?${params.toString()}`)
  //   } else {
  //     history.replace({ pathname: locationData.pathname, search: params.toString() })
  //   }
  // }

  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const updateQueryParams = (catName) => {
    // Define the new URL to redirect to
    const newUrl = `/products?c=${encodeURIComponent(catName)}`

    // If the current path is '/brand', redirect to the new URL
    if (locationData.pathname === '/brand' || query.get('productId') || locationData.pathname === '/products') {
      history.replace(newUrl)
    } else {
      const params = new URLSearchParams(locationData.search)
      params.set('c', catName)

      // Replace existing URL parameters
      if (locationData.search === '' && query.get('c') === null) {
        history.push(newUrl) // Push new URL if no existing params
      } else {
        history.replace({ pathname: locationData.pathname, search: params.toString() })
      }
    }
  }

  const { theme } = useTheme()

  return (
    <FooterContainer theme={theme}>
      <FooterWrapper>
        <FooterDiv>
          <FooterHeading>Contact Us</FooterHeading>
          <ContactHeading>
            <EmailIcon />
            <HeadingSection>
              <FooterHeadingTitle>Mail Us</FooterHeadingTitle>
              <ContactHeading>Info@himira.co.in</ContactHeading>
            </HeadingSection>
          </ContactHeading>
          <ContactHeading>
            <PhoneIcon />
            <HeadingSection>
              <FooterHeadingTitle>Call Us</FooterHeadingTitle>
              <ContactHeading>+91-9816012233</ContactHeading>
            </HeadingSection>
          </ContactHeading>
          <SocialMediaIconWrapper>
            <SocialMediaSectionTitle>Follow Us</SocialMediaSectionTitle>
            <SocialMediaIconsWrapper>
              <a href="https://www.facebook.com/himiraofficial/" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/himira_official/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://www.youtube.com/@HimachalPradeshSRLM" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon />
              </a>
            </SocialMediaIconsWrapper>
          </SocialMediaIconWrapper>
        </FooterDiv>
        <FooterRightDiv>
          <FooterDiv>
            <FooterHeading>Categories</FooterHeading>
            {categoryList?.map((item, index) => (
              <li
                key={`category-${index}`}
                onClick={() => updateQueryParams(item.routeName)}
                style={{ listStyle: 'none', cursor: 'pointer' }}
              >
                <FooterHeading1>{item.shortName}</FooterHeading1>
              </li>
            ))}
          </FooterDiv>
          <FooterDiv>
            <FooterHeading>Need Help</FooterHeading>
            <FooterHeading1>
              <Link to="/contact-us">Contact Us</Link>
            </FooterHeading1>
            <FooterHeading1 onClick={() => setTrackOrderModal(true)}>Track Order</FooterHeading1>
            <FooterHeading1>
              <Link to="/returns-refund">Returns & Refunds</Link>
            </FooterHeading1>
            <FooterHeading1>
              <Link to="/faq">FAQ&apos;s</Link>
            </FooterHeading1>
          </FooterDiv>
          <FooterDiv>
            <FooterHeading>More Info</FooterHeading>
            <FooterHeading1>
              <Link to="/about-us">About Us</Link>
            </FooterHeading1>
            <FooterHeading1>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </FooterHeading1>
            <FooterHeading1>
              <Link to="/shipping-policy">Shipping Policy</Link>
            </FooterHeading1>
            <FooterHeading1>
              <Link to="/cancellation-policy">Cancellation Policy</Link>
            </FooterHeading1>
          </FooterDiv>
        </FooterRightDiv>
      </FooterWrapper>
      <CopyRightSection>
        <CopyRight> © {new Date().getFullYear()} himira. All rights reserved.</CopyRight>
      </CopyRightSection>
      {trackOrderModal && (
        <ModalComponent open={trackOrderModal} onClose={() => setTrackOrderModal(false)} title="Track Order">
          <TrackOrderModal onClose={() => setTrackOrderModal(false)} />
        </ModalComponent>
      )}
    </FooterContainer>
  )
}

export default Footer
