import React, { useContext, useEffect, useState } from 'react'
import MyOrderIcon from 'assets/svg/MyOrderIcon'
import WishlistIcon from 'assets/svg/HeartIcon'
import InfoIcon from 'assets/svg/infoIcon'
import SignoutIcon from 'assets/svg/SignoutIcon'
import ComplaintsIcon from 'assets/svg/ComplaintsIcon'
import {
  SidebarContainer,
  HeaderWrapper,
  UserLabel,
  WelcomeMessage,
  ListItemWrapper,
  ListWrapper,
  SidebarTitle,
  SidebarIcon,
} from 'styles/checkoutOrders'
import { getSelectCall } from 'api/axios'
import useCancellablePromise from 'api/cancelRequest'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'

const CheckoutSideBar = ({ handleSidebarClick, activeIndex, setActiveIndex }) => {
  const [userInfo, setUserInfo] = useState()
  const { cancellablePromise } = useCancellablePromise()
  const dispatch = useContext(ToastContext)

  useEffect(() => {
    getUserInfo()
  }, [])

  const getUserInfo = async () => {
    try {
      const res = await cancellablePromise(getSelectCall(`/clientApis/getUserProfile`))
      setUserInfo(res.data)
      // setImgLink(userData.userImage)
    } catch (err) {
      if (err.response.status !== 401)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: err?.response?.data?.error?.message,
          },
        })
    }
  }

  const data = [
    {
      icon: <MyOrderIcon />,
      title: 'My Order',
      value: 'my-order',
    },
    {
      icon: <ComplaintsIcon />,
      title: 'Complaints',
      value: 'complaints',
    },
    {
      icon: <WishlistIcon />,
      title: 'Wishlist',
      value: 'wishlist',
    },
    {
      icon: <InfoIcon />,
      title: 'My Info',
      value: 'my-info',
    },
    {
      icon: <SignoutIcon />,
      title: 'Sign out',
      value: 'sign-out',
    },
  ]

  return (
    <SidebarContainer>
      <HeaderWrapper>
        <UserLabel>{userInfo?.userName}</UserLabel>
        <WelcomeMessage>Welcome to your Account</WelcomeMessage>
      </HeaderWrapper>
      <ListWrapper>
        {data?.map((item, index) => (
          <ListItemWrapper
            isActive={index === activeIndex}
            key={index}
            onClick={() => {
              setActiveIndex(index)
              handleSidebarClick(item?.value)
            }}
          >
            <SidebarIcon>{item?.icon}</SidebarIcon>
            <SidebarTitle>{item?.title}</SidebarTitle>
          </ListItemWrapper>
        ))}
      </ListWrapper>
    </SidebarContainer>
  )
}

export default CheckoutSideBar
