import { getCall } from '../../../../api/axios'
import { getOrCreateDeviceId, getUserId } from '../../../../helper'
const userId = getUserId()

export const getCartItems = async () => {
  const deviceId = await getOrCreateDeviceId()

  // const url = `/clientApis/v2/cart/${user.id ? user.id : customCartId}`
  const url = `/clientApis/v2/cart/${userId}/${deviceId}`

  const res = await getCall(url)
  localStorage.setItem('cartItems', JSON.stringify(res))
  return res
}
