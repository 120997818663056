import React, { useContext, useState, useRef } from 'react'
import styles from '../../../../styles/search-product-modal/searchProductModal.module.scss'
import ErrorMessage from '../../../shared/error-message/errorMessage'
import Input from '../../../shared/input/input'
import { toast_actions, toast_types } from '../../../shared/toast/utils/toast'
import { getValueFromCookie } from '../../../../utils/cookies'
import { ToastContext } from '../../../../context/toastContext'
import validator from 'validator'
import useCancellablePromise from '../../../../api/cancelRequest'
import AddressRadioButton from '../../initialize-order/address-details/address-radio-button/addressRadioButton'
// import cancelRadioStyles from '../../../../styles/cart/cartView.module.scss'
import Like from '../../../shared/svg/like'
import Dislike from '../../../shared/svg/dislike'
import labelStyles from '../../../shared/input/input.module.scss'
import { postCheckoutCall } from '../../../../api/axios'
import {
  Container,
  Wrapper,
  RadioContent,
  RadioTitle,
  ActionSection,
  RatingSection,
  Title,
  RatingOptionSection,
  ButtonSection,
  ActionButtonSection,
} from 'styles/ActionModal'
import CustomButton from 'components/customButton'

export default function CustomerActionCard({ supportActionDetails, onClose, onSuccess, refetch, complaintData }) {
  // CONSTANTS
  const token = getValueFromCookie('token')
  token
  // CONSTANTS
  const ACTION_TYPES = {
    closeIssue: 'CLOSE_ISSUE',
    escalateIssue: 'ESCALATE_ISSUE',
  }

  // STATES
  const [inlineError, setInlineError] = useState({
    remarks_error: '',
  })
  const [loading, setLoading] = useState(false)
  const [customerRemarks, setCustomerRemarks] = useState('')
  const [selectedCancelType, setSelectedCancelType] = useState(ACTION_TYPES.closeIssue)
  const [like, setLike] = useState()
  const [dislike, setDislike] = useState()

  // REFS
  const cancelPartialEventSourceResponseRef = useRef(null)
  // const eventTimeOutRef = useRef([])

  // CONTEXT
  const dispatch = useContext(ToastContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  // use this function to dispatch error
  function dispatchToast(message, type) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  // use this function to check the user entered remarks
  function checkRemarks() {
    if (validator.isEmpty(customerRemarks)) {
      setInlineError((error) => ({
        ...error,
        remarks_error: 'Please enter the remarks',
      }))
      return false
    }

    return true
  }

  // use this function to check the user rating
  function checkRating() {
    if (like === undefined && dislike === undefined) {
      setInlineError((error) => ({
        ...error,
        remarks_error: 'Please choose a rating',
      }))
      return false
    }

    return true
  }

  async function contactSupport() {
    if (selectedCancelType === ACTION_TYPES.closeIssue && !checkRating()) {
      return
    }

    if (selectedCancelType === ACTION_TYPES.escalateIssue && !checkRemarks()) {
      return
    }

    cancelPartialEventSourceResponseRef.current = []
    setLoading(true)
    try {
      const { bpp_id, issue_actions, issueId, transaction_id, created_at, domain } = supportActionDetails
      const dataObject = {
        context: {
          action: 'issue',
          bpp_id,
          domain,
          timestamp: new Date(),
          transaction_id,
        },
      }

      if (selectedCancelType === ACTION_TYPES.closeIssue) {
        dataObject.message = {
          issue: {
            issueId: issueId,
            status: 'CLOSED',
            rating: like ? 'THUMBS-UP' : 'THUMBS-DOWN',
            updated_at: new Date(),
            created_at,
            issue_actions: {
              complainant_actions: [
                ...issue_actions.complainant_actions,

                {
                  complainant_action: 'CLOSE',
                  short_desc: 'Complaint closed',
                  updated_at: new Date(),
                  updated_by: issue_actions.complainant_actions[0].updated_by,
                },
              ],
            },
          },
        }
      } else {
        dataObject.message = {
          issue: {
            issueId: issueId,
            status: 'OPEN',
            issue_type: 'GRIEVANCE',
            updated_at: new Date(),
            created_at,
            issue_actions: {
              complainant_actions: [
                ...issue_actions.complainant_actions,
                {
                  complainant_action: 'ESCALATE',
                  short_desc: customerRemarks,
                  updated_at: new Date(),
                  updated_by: issue_actions.complainant_actions[0].updated_by,
                },
              ],
            },
          },
        }
      }

      const data = await cancellablePromise(postCheckoutCall('/issueApis/v1/issue', dataObject))

      if (data.message.ack.status === 'ACK') {
        refetch()
        onClose()
      }

      //Error handling workflow eg, NACK
      if (data.message && data.message.ack.status === 'NACK') {
        setLoading(false)
        dispatchToast('Something went wrong', toast_types.error)

        onSuccess([
          {
            respondent_action: complaintData?.issue_actions?.complainant_actions?.complainant_action,
            short_desc: complaintData?.issue_actions?.complainant_actions?.short_desc,
            updated_at: new Date(),
            updated_by: issue_actions.complainant_actions[0].updated_by,
          },
        ])
      }
    } catch (err) {
      setLoading(false)
      dispatchToast(err?.response?.data?.message, toast_types.error)
    }
  }

  const review = (type) => {
    setInlineError((inlineError) => ({
      ...inlineError,
      remarks_error: '',
    }))
    if (type === 'like') {
      setLike(true)
      setDislike(false)
    } else {
      setLike(false)
      setDislike(true)
    }
  }

  const handleEscalateIssueClick = (setSelectedCancelType, setInlineError) => {
    setSelectedCancelType(ACTION_TYPES.escalateIssue)
    setInlineError((inlineError) => ({
      ...inlineError,
      remarks_error: '',
    }))
  }

  const handleCloseIssueClick = (setSelectedCancelType, setInlineError) => {
    setSelectedCancelType(ACTION_TYPES.closeIssue)
    setInlineError((inlineError) => ({
      ...inlineError,
      remarks_error: '',
    }))
  }

  return (
    <Container className={styles.popup_card}>
      <Wrapper>
        <AddressRadioButton
          disabled={loading}
          checked={selectedCancelType === ACTION_TYPES.closeIssue}
          onClick={() => handleCloseIssueClick(setSelectedCancelType, setInlineError)}
        >
          <RadioContent>
            <RadioTitle>Close</RadioTitle>
          </RadioContent>
        </AddressRadioButton>

        <AddressRadioButton
          disabled={loading}
          checked={selectedCancelType === ACTION_TYPES.escalateIssue}
          onClick={() => handleEscalateIssueClick(setSelectedCancelType, setInlineError)}
        >
          <RadioContent>
            <RadioTitle>Escalate</RadioTitle>
          </RadioContent>
        </AddressRadioButton>
      </Wrapper>
      <ActionSection>
        {selectedCancelType === ACTION_TYPES.closeIssue ? (
          <RatingSection>
            <Title className={`${labelStyles.required}`}>Choose Rating</Title>
            <RatingOptionSection>
              <ButtonSection onClick={() => review('like')}>
                <Like like={like} />
              </ButtonSection>
              <ButtonSection onClick={() => review('dislike')}>
                <Dislike dislike={dislike} />
              </ButtonSection>
            </RatingOptionSection>
          </RatingSection>
        ) : (
          <Input
            label_name="Remarks"
            type="text"
            placeholder="Enter the remarks."
            id="remarks"
            has_error={inlineError.remarks_error}
            value={customerRemarks}
            onChange={(event) => {
              const remarks = event.target.value
              setCustomerRemarks(remarks)
              setInlineError((inlineError) => ({
                ...inlineError,
                remarks_error: '',
              }))
            }}
            required
          />
        )}
        {inlineError.remarks_error && <ErrorMessage>{inlineError.remarks_error}</ErrorMessage>}
      </ActionSection>

      <ActionButtonSection>
        <CustomButton
          disabled={loading}
          label={'Cancel'}
          variant="outline1"
          onClick={() => {
            onClose()
          }}
        />
        <CustomButton
          disabled={loading}
          label={'Confirm'}
          variant="contained1"
          onClick={() => {
            contactSupport()
          }}
        />
      </ActionButtonSection>
    </Container>
  )
}
