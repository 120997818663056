import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const FooterContainer = styled.div`
  background: ${theme.HPSECONDARYCOLOR};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 56px 100px 0 100px;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  @media (max-width: 767px) {
    padding: 16px;
    gap: 17px;
  }
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  align-items: flex-start;
  border-bottom: 1px solid #e8bf1233;
  padding: 40px 0;
  @media screen and (max-width: 467px) {
    flex-direction: column;
    border-top: none;
  }
`
export const FooterDiv = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
  }
`
export const FooterRightDiv = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    gap: 38px;
  }
`
export const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const FooterHeading = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const DownloadFooterHeading = styled.div`
  font-weight: 500;
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`
export const FooterHeading1 = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  list-style: none;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${theme.HPFOOTERTEXTCOLOR};
  cursor: pointer;
  a {
    color: ${theme.HPFOOTERTEXTCOLOR};
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    svg {
      width: 17px;
      height: 17px;
    }
    a {
      font-size: 12px;
    }
  }
  .disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const ContactHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
  a {
    color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
    text-decoration: none;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
  .disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const FooterHeadingTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  list-style: none;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  a {
    color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
    text-decoration: none;
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    svg {
      width: 17px;
      height: 17px;
    }
  }
  .disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const DownloadAppContainer = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 450px) {
    display: grid;
    justify-content: center;
    gap: 12px;
  }
`
export const FooterIcons = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 450px) {
    justify-content: center;
    svg {
      width: 28px;
      height: 28px;
    }
  }
`
export const DownloadImages = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  align-items: flex-start;
  margin-top: 10px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    flex-direction: row;
    img {
      width: 140px;
    }
  }
`
export const DownloadItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`
export const CopyRightSection = styled.div`
  width: 100%;
  padding-bottom: 20px;
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
`
export const SocialMediaIconWrapper = styled.div`
  width: 100%;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 767px) {
    margin-top: 12px;
  }
`
export const SocialMediaSectionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
`
export const SocialMediaIconsWrapper = styled.div`
  display: flex;
  gap: 12px;
`
export const CopyRight = styled.p`
  text-align: center;
  padding-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
`
