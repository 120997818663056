import axios from 'axios'
import Cookies from 'js-cookie'

import axiosHttp from 'utils/axios'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
export async function getCall(url, params = null) {
  try {
    const response = await axiosHttp.get(url, {
      params: params,
    })
    return response.data
  } catch (err) {
    if (err.response.status !== 401) {
      throw err
    }
  }
}

export async function getSelectCall(url, params = null) {
  const token = Cookies.get('token')
  try {
    const response = await axiosHttp.get(url, {
      params: params,
      headers: { ...(token && { Authorization: `Bearer ${token}` }) },
    })

    return response.data
  } catch (err) {
    if (err.response.status !== 401) {
      throw err
    }
  }
}

export async function postLoginCall(url, params) {
  try {
    const response = await axiosHttp.post(url, params)

    return response.data
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message)
    }

    throw err
  }
}

export async function postCall(url, params) {
  const token = Cookies.get('token')
  try {
    const response = await axiosHttp.post(url, params, {
      headers: {
        Authorization: token,
      },
    })
    return response.data
  } catch (err) {
    if (err.response) {
      throw err
    }
  }
}

export async function postCheckoutCall(url, params) {
  const token = Cookies.get('token')

  try {
    const response = await axiosHttp.post(url, params, {
      headers: { ...(token && { Authorization: `Bearer ${token}` }) },
    })
    return response.data
  } catch (err) {
    if (err.response) {
      throw err
    }
  }
}

export async function putCall(url, params) {
  try {
    const response = await axiosHttp.put(url, params, {})
    return response.data
  } catch (err) {
    if (err.response) {
      throw err
    }
  }
}

export async function deleteCall(url) {
  try {
    const response = await axiosHttp.delete(url, {})
    return response.data
  } catch (err) {
    if (err.response) {
      throw err
    }
  }
}

export async function deleteWithAuthentication(url) {
  const token = Cookies.get('token')

  try {
    const response = await axiosHttp.delete(url, {
      headers: { ...(token && { Authorization: `Bearer ${token}` }) },
    })
    return response.data
  } catch (err) {
    if (err.response) {
      throw err
    }
  }
}

export function makeCancelable(promise) {
  let isCanceled = false
  const wrappedPromise = new Promise((resolve, reject) => {
    // Suppress resolution and rejection if canceled
    promise.then((val) => !isCanceled && resolve(val)).catch((error) => !isCanceled && reject(error))
  })
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    },
  }
}
