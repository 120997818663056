import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#fff"
      d="M18.667 19.25a1.167 1.167 0 0 0-2.334 0h2.334Zm-2.334-9.844a1.167 1.167 0 0 0 2.334 0h-2.334Zm-3.5 3.427a1.167 1.167 0 0 0 0 2.334v-2.334ZM24.5 14l.825.825a1.167 1.167 0 0 0 0-1.65L24.5 14Zm-2.092-3.742a1.167 1.167 0 1 0-1.65 1.65l1.65-1.65Zm-1.65 5.834a1.167 1.167 0 0 0 1.65 1.65l-1.65-1.65ZM7 4.667h8.167V2.333H7v2.334Zm8.167 18.666H7v2.334h8.167v-2.334Zm-9.334-1.166V5.833H3.5v16.334h2.333Zm10.5-2.917v2.917h2.334V19.25h-2.334Zm-3.5-4.083H24.5v-2.334H12.833v2.334Zm12.492-1.992-2.917-2.917-1.65 1.65 2.917 2.917 1.65-1.65Zm-1.65 0-2.917 2.917 1.65 1.65 2.917-2.917-1.65-1.65ZM7 23.333a1.167 1.167 0 0 1-1.167-1.166H3.5a3.5 3.5 0 0 0 3.5 3.5v-2.334Zm8.167 2.334a3.5 3.5 0 0 0 3.5-3.5h-2.334c0 .644-.522 1.166-1.166 1.166v2.334Zm0-21c.644 0 1.166.522 1.166 1.166h2.334a3.5 3.5 0 0 0-3.5-3.5v2.334ZM7 2.333a3.5 3.5 0 0 0-3.5 3.5h2.333c0-.644.523-1.166 1.167-1.166V2.333Zm9.333 3.5v3.573h2.334V5.833h-2.334Z"
    />
  </svg>
)
export default SvgComponent
