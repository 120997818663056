import React, { useState, useEffect, useContext } from 'react'
import Slider from 'react-slick'
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'
import { formatIndianRupees, getOrCreateDeviceId, getUserId } from 'helper'
import { CalculateDiscount } from 'utils/helper'
import { ToastContext } from 'context/toastContext'
import { SearchContext } from 'context/searchContext'
import { CartContext } from 'context/cartContext'
import { getSelectCall, postLoginCall, deleteWithAuthentication } from 'api/axios'
import { search_types } from 'constants/searchTypes'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { isLoggedIn } from 'utils/validateToken'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import ModalComponent from 'components/common/Modal'
import CustomButton from 'components/customButton'
import LoginModal from 'views/LoginModal'
import PlaceOrderModal from 'views/OrderModal'
import ButtonIcon from 'assets/images/HpImages/Button.png'
import HpNextIcon from 'assets/svg/HpNextIcon'
import HpPreviousIcon from 'assets/svg/HpPreviousIcon'
import HpWishlistIcon from 'assets/svg/HPSvgs/HpWishlistIcon'
import HPWishlistFilledIcon from 'assets/svg/HPSvgs/HPWishlistFilledIcon'
import {
  RecommendedProductsSection,
  ProductDetailHeading,
  ProductSection,
  CardWrapper,
  ProductImageWrapper,
  RecommendedProductWishlistWrapper,
  RecommendedProductName,
  RecommendedProductPriceWrapper,
  RecommendedProductOriginalPrice,
  RecommendedProductPrice,
  DiscountPercentage,
  ButtonWrapper,
  IconButton,
} from 'components/application/product-list/product-details/style'

const RecommendedProducts = (recommendedCategoryId) => {
  const history = useHistory()
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const { fetchCartItems, cartItems } = useContext(CartContext)
  const dispatch = useContext(ToastContext)
  const [recommendedProductsData, setRecommendedProductsData] = useState()
  const [deviceId, setDeviceId] = useState()
  const [buttonDisable, setButtonDisable] = useState({})
  const [customization_state, setCustomizationState] = useState({})
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [orderModal, setOrderModal] = useState(false)
  const [buyNow, setBuyNow] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [loading, setLoading] = useState({})
  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  const useQuery = () => {
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const recommendedProductsSettings = {
    dots: false,
    infinite: recommendedProductsData?.length > 4,
    speed: 500,
    slidesToShow: recommendedProductsData?.length >= 4 ? 5 : recommendedProductsData?.length,
    slidesToScroll: 1,
    arrows: recommendedProductsData?.length > 4,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: recommendedProductsData?.length >= 4 ? 4 : recommendedProductsData?.length,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: recommendedProductsData?.length >= 3 ? 3 : recommendedProductsData?.length,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: recommendedProductsData?.length >= 2 ? 2 : recommendedProductsData?.length,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
    ],
    nextArrow: (
      <IconButton>
        <HpNextIcon />
      </IconButton>
    ),
    prevArrow: (
      <IconButton>
        <HpPreviousIcon />
      </IconButton>
    ),
  }
  const getRecommendedProducts = async () => {
    const userId = getUserId()
    setLoading(true)
    const params = {
      categoryId:
        recommendedCategoryId?.recommendedCategoryId === 'Gift Voucher'
          ? 'Gift Boxes'
          : `${recommendedCategoryId?.recommendedCategoryId}`,
    }
    try {
      const data = await getSelectCall(`/clientApis/v2/search/${userId}?deviceId=${deviceId}`, params)
      setRecommendedProductsData(data?.response?.data || [])
      setLoading(false)
      return data
    } catch (err) {
      setLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the products. Please try again.',
        },
      })
    }
  }

  useEffect(() => {
    if (deviceId && recommendedCategoryId) {
      getRecommendedProducts()
    }
  }, [deviceId, recommendedCategoryId])

  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  useEffect(() => {
    execute()
  }, [])

  const handleAddToCart = async (product) => {
    const userId = getUserId()

    if (buttonDisable[product.id]) return
    ReactGA.event({
      category: 'Recommented Products',
      action: 'Click',
      label: 'Add to Cart',
    })

    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`

    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const data = await postLoginCall(url, payload)
      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }
  }

  useEffect(() => {
    if (recommendedProductsData && recommendedProductsData.length > 0) {
      const buttonStates = recommendedProductsData.reduce((acc, product) => {
        const inCart = cartItems.some((item) => item.item.id === product.id)
        acc[product.id] = inCart
        return acc
      }, {})

      setButtonDisable(buttonStates)
    }
  }, [cartItems, recommendedProductsData])

  const handleBuyNow = (product) => {
    ReactGA.event({
      category: 'Top Selling',
      action: 'Click',
      label: 'Buy Now',
    })

    setSelectedProduct(product)
    if (!isLoggedIn()) {
      setBuyNow(true)
      setLoginModal(true)
      return
    } else {
      setOrderModal(true)
    }
  }

  const handleWishlist = async (product) => {
    const userId = getUserId()
    const { item_details } = product
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    // fetchWishlist()
    const deviceId = await getOrCreateDeviceId()
    const url = `/clientApis/v2/wishlist/${userId}/${deviceId}`
    const payload = {
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    setRecommendedProductsData((prevItems) =>
      prevItems.map((item) => (item?.item_details.id === item_details?.id ? { ...item, wishlistAdded: true } : item)),
    )

    try {
      const res = await postLoginCall(url, payload)
      if (res.status !== 'error') {
        localStorage.setItem('wishlistItems', JSON.stringify(res))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'It looks like the item already exists in the Wishlist. Please check',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, you cannot add this item to your Wishlist due to technical glitch. Please try again.',
        },
      })
    }
  }

  const handleRemoveProductFromWishlist = async (item_details, e) => {
    const userId = getUserId()
    e.stopPropagation()

    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const deviceId = await getOrCreateDeviceId()
      const url = `/clientApis/v2/item/wishlist/${userId}/${deviceId}/${item_details?.id}`
      await deleteWithAuthentication(url)

      setRecommendedProductsData((prevItems) =>
        prevItems.map((item) =>
          item?.item_details.id === item_details?.id ? { ...item, wishlistAdded: false } : item,
        ),
      )

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item removed from your Wishlist',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the Wishlist products. Please try again.',
        },
      })
    }
  }

  return (
    <>
      {recommendedProductsData?.length > 0 && (
        <RecommendedProductsSection>
          <ProductDetailHeading>Recommended Products</ProductDetailHeading>
          <ProductSection>
            <Slider {...recommendedProductsSettings}>
              {recommendedProductsData?.map((item, index) => {
                const { item_details } = item
                const discount = CalculateDiscount(
                  item?.item_details?.price?.maximum_value,
                  item?.item_details?.price?.value,
                )
                return (
                  <CardWrapper key={index}>
                    <ProductImageWrapper>
                      <RecommendedProductWishlistWrapper>
                        {item.wishlistAdded ? (
                          <HPWishlistFilledIcon
                            onClick={async (e) => {
                              handleRemoveProductFromWishlist(item_details, e)
                            }}
                          />
                        ) : (
                          <HpWishlistIcon
                            onClick={() => {
                              handleWishlist(item)
                            }}
                          />
                        )}
                      </RecommendedProductWishlistWrapper>
                      <img
                        src={item_details?.descriptor?.symbol}
                        alt="Product"
                        onClick={() => {
                          history.push(`/products?productId=${item?.id}`)
                        }}
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    </ProductImageWrapper>
                    <RecommendedProductName>{item_details?.descriptor?.name}</RecommendedProductName>
                    <RecommendedProductPriceWrapper>
                      <RecommendedProductOriginalPrice>
                        ₹{formatIndianRupees(parseFloat(item_details?.price?.maximum_value))}
                      </RecommendedProductOriginalPrice>
                      <RecommendedProductPrice>
                        ₹{formatIndianRupees(parseFloat(item_details?.price?.value))}
                      </RecommendedProductPrice>
                      {discount > 0 && <DiscountPercentage>{Math.round(discount)}% OFF</DiscountPercentage>}
                    </RecommendedProductPriceWrapper>
                    <ButtonWrapper>
                      {item_details?.quantity?.available?.count === '0' ? (
                        <CustomButton
                          label="Out of stock"
                          variant="contained1"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          disabled
                          className="outOfStock"
                        />
                      ) : (
                        <>
                          <CustomButton
                            variant="text"
                            className="cart-button"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handleAddToCart(item)
                              setButtonDisable((prev) => ({ ...prev, [item?.id]: true }))
                            }}
                            disabled={
                              item_details?.quantity?.available?.count === '0' ||
                              buttonDisable[item?.id] ||
                              loading[item?.id]
                            }
                            icon={<img src={ButtonIcon} height={36} width={36} alt="Button Icon" />}
                          />
                          <CustomButton
                            label="Buy Now"
                            variant="contained1"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handleBuyNow(item)
                            }}
                            disabled={item_details?.quantity?.available?.count === '0' || loading[item?.id]}
                          />
                        </>
                      )}
                    </ButtonWrapper>
                  </CardWrapper>
                )
              })}
            </Slider>
            <CustomizationRenderer
              customization_state={customization_state}
              setCustomizationState={setCustomizationState}
            />
          </ProductSection>
          {loginModal && (
            <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
              <LoginModal onClose={() => setLoginModal(false)} buyNow={buyNow} setOrderModal={setOrderModal} />
            </ModalComponent>
          )}
          {orderModal && (
            <ModalComponent
              open={orderModal}
              onClose={() => setOrderModal(false)}
              title="Get Ready To Shop !"
              titleBg={true}
            >
              <PlaceOrderModal onClose={() => setOrderModal(false)} product={selectedProduct} />
            </ModalComponent>
          )}
        </RecommendedProductsSection>
      )}
    </>
  )
}

export default RecommendedProducts
