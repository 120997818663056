import { useMutation } from 'react-query'
import instance from '../services/axiosInstance'
import Cookies from 'js-cookie'

const post = async ({ url, payload }) => {
  const token = Cookies.get('token')
  const { data } = await instance.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

const usePost = () => useMutation(post)

export default usePost
