import moment from 'moment'
import validator from 'validator'

export const removeNullValues = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') removeNullValues(v)
    if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
      if (Array.isArray(object)) object.splice(k, 1)
      else if (!(v instanceof Date)) delete object[k]
    }
  })
  return object
}

export const compareDateWithDuration = (duration, dateStr) => {
  const currentDate = new Date()
  const providedDate = new Date(dateStr)
  // Parse the duration
  const durationInMilliseconds = parseDuration(duration)
  // Add the duration to the provided date
  const newDate = new Date(providedDate.getTime() + durationInMilliseconds)
  // Compare the new date with the current date
  return currentDate.getTime() > newDate.getTime()
}

// Parse ISO 8601 duration format (PT1H, PT30S, etc.)
export function parseDuration(duration) {
  return moment.duration(duration).asMilliseconds()
}

export const checkName = (address, setError) => {
  if (validator.isEmpty(address?.name.trim())) {
    setError((error) => ({
      ...error,
      name_error: 'Please enter Name',
    }))
    return false
  }

  return true
}

export const checkEmail = (address, setError) => {
  if (validator.isEmpty(address?.email.trim())) {
    setError((error) => ({
      ...error,
      email_error: 'Please enter Email',
    }))
    return false
  }

  if (!validator.isEmail(address?.email.trim())) {
    setError((error) => ({
      ...error,
      email_error: 'Please enter a valid Email',
    }))
    return false
  }

  return true
}

export const checkPhoneNumber = (address, setError) => {
  if (validator.isEmpty(address?.phone.trim())) {
    setError((error) => ({
      ...error,
      phone_error: 'Please enter a valid phone number',
    }))
    return false
  }

  if (!validator.isMobilePhone(address?.phone.trim(), 'en-IN')) {
    setError((error) => ({
      ...error,
      phone_error: 'Please enter a valid phone number',
    }))
    return false
  }

  return true
}

export const checkStreetName = (address, setError) => {
  if (!address.street || validator.isEmpty(address?.street.trim())) {
    setError((error) => ({
      ...error,
      street_name_error: 'Street Name cannot be empty',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      street_name_error: '',
    }))
  }

  return true
}

export const checkLandMark = (address, setError) => {
  if (validator.isEmpty(address?.door.trim())) {
    setError((error) => ({
      ...error,
      door_error: 'Landmark cannot be empty',
    }))
    return false
  }

  return true
}

export const checkCity = (address, setError) => {
  if (!address.city || validator.isEmpty(address?.city.trim())) {
    setError((error) => ({
      ...error,
      city_name_error: 'City Name cannot be empty',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      city_name_error: '',
    }))
  }

  return true
}

export const checkState = (address, setError) => {
  if (!address.state || validator.isEmpty(address?.state.trim())) {
    setError((error) => ({
      ...error,
      state_name_error: 'State Name cannot be empty',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      state_name_error: '',
    }))
  }

  return true
}

export const checkTag = (address, setError) => {
  if (validator.isEmpty(address?.tag.trim())) {
    setError((error) => ({
      ...error,
      tag_error: 'Please select tag',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      tag_error: '',
    }))
  }

  return true
}

export const checkPinCode = (address, setError) => {
  if (!address.areaCode || validator.isEmpty(address?.areaCode?.trim())) {
    setError((error) => ({
      ...error,
      areaCode_error: 'Pin code cannot be empty',
    }))
    return false
  } else if (address?.areaCode?.length < 6) {
    setError((error) => ({
      ...error,
      areaCode_error: 'Please enter a valid Pin Code',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      areaCode_error: '',
    }))
  }

  return true
}

export const checkDoor = (address, setError) => {
  if (!address.door || validator.isEmpty(address?.name.trim())) {
    setError((error) => ({
      ...error,
      door_error: 'Building cannot be empty',
    }))
    return false
  } else {
    setError((error) => ({
      ...error,
      door_error: '',
    }))
  }

  return true
}

export const CalculateDiscount = (totalPrice, price) => {
  let discount = 0

  if (totalPrice >= price) {
    discount = ((totalPrice - price) * 100) / totalPrice
    if (discount > 100) {
      discount = 100
    }

    discount = Math.round(discount * 100) / 100
  }

  return discount
}
