import React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

const Tabs = (props) => {
  const { value, handleChange, items, panelItems, isCenteredPage } = props

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          {/* Conditional rendering based on isCenteredPage prop */}
          {isCenteredPage ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                // scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                TabIndicatorProps={{ style: { display: 'none' } }}
              >
                {items.map((item, index) => (
                  <Tab
                    key={index}
                    label={item.label}
                    value={item.value}
                    sx={{
                      ...(value === item.value && {
                        height: '42px',
                        background: '#734D00', // Active tab background color
                        color: '#FFFFFF !important',
                        borderRadius: '8px',
                      }),
                    }}
                  />
                ))}
              </TabList>
            </Box>
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {items.map((item, index) => (
                  <Tab key={index} label={item.label} value={item.value} />
                ))}
              </TabList>
            </Box>
          )}

          {/* Keep the TabPanel rendering unchanged */}
          {panelItems.map((item, index) => (
            <TabPanel className="mui-tab-class" key={index} value={item.id}>
              {item.data}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </div>
  )
}

export default Tabs
