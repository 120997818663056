import React, { useEffect, useState } from 'react'
import useStyles from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, Typography } from '@mui/material'
import Radio from '../../../common/Radio'
import Checkbox from '../../../common/Checkbox'
import { createCustomizationAndGroupMapping } from './utils'

const CustomizationRenderer = (props) => {
  const { productPayload, customization_state, setCustomizationState, isEditFlow = false, setItemOutOfStock } = props

  const classes = useStyles()
  const [customizationGroups, setCustomizationGroups] = useState([])
  const [customizations, setCustomizations] = useState([])
  // const [highestSeq] = useState(0)
  const [customizationToGroupMap, setCustomizationToGroupMap] = useState({})
  // const [customizationMap, setGroupToCustomizationMap] = useState({})

  const formatCustomizationGroups = (customisation_groups) => {
    const formattedCustomizationGroups = customisation_groups?.map((group) => {
      const configTags = group.tags.find((tag) => tag.code === 'config').list
      const minConfig = configTags.find((tag) => tag.code === 'min').value
      const maxConfig = configTags.find((tag) => tag.code === 'max').value
      const inputTypeConfig = configTags.find((tag) => tag.code === 'input').value
      const seqConfig = configTags.find((tag) => tag.code === 'seq').value

      const customizationObj = {
        id: group.local_id,
        name: group.descriptor.name,
        inputType: inputTypeConfig,
        minQuantity: parseInt(minConfig),
        maxQuantity: parseInt(maxConfig),
        seq: parseInt(seqConfig),
      }

      if (inputTypeConfig === 'input') {
        customizationObj.special_instructions = ''
      }

      return customizationObj
    })

    return formattedCustomizationGroups
  }

  const formatCustomizations = (customisation_items) => {
    const customizations = customisation_items?.map((customization) => {
      const itemDetails = customization.item_details
      const parentTag = itemDetails.tags.find((tag) => tag.code === 'parent')
      const vegNonVegTag = itemDetails.tags.find((tag) => tag.code === 'veg_nonveg')
      const isDefaultTag = parentTag.list.find((tag) => tag.code === 'default')
      const isDefault = isDefaultTag?.value.toLowerCase() === 'yes'
      const childTag = itemDetails.tags.find((tag) => tag.code === 'child')
      const childs = childTag?.list.map((item) => item.value)

      return {
        id: itemDetails.id,
        name: itemDetails.descriptor.name,
        price: itemDetails.price.value,
        inStock: itemDetails.quantity.available.count > 0,
        parent: parentTag ? parentTag.list.find((tag) => tag.code === 'id').value : null,
        child: childTag ? childTag.list.find((tag) => tag.code === 'id').value : null,
        childs: childs?.length > 0 ? childs : null,
        isDefault: isDefault ?? false,
        vegNonVeg: vegNonVegTag ? vegNonVegTag.list[0].code : '',
      }
    })
    return customizations
  }

  function findMinMaxSeq(customizationGroups) {
    if (!customizationGroups || customizationGroups.length === 0) {
      return { minSeq: undefined, maxSeq: undefined }
    }

    let minSeq = Infinity
    let maxSeq = -Infinity

    customizationGroups.forEach((group) => {
      const seq = group.seq
      if (seq < minSeq) {
        minSeq = seq
      }

      if (seq > maxSeq) {
        maxSeq = seq
      }
    })

    return { minSeq, maxSeq }
  }

  useEffect(() => {
    if (productPayload) {
      const { customisation_groups, customisation_items } = productPayload
      const customGroup = productPayload.item_details.tags.find((item) => item.code == 'custom_group')
      if (customGroup && customGroup.list.length > 0) {
        setCustomizationGroups(formatCustomizationGroups(customisation_groups))
      } else {
        setCustomizationGroups([])
      }

      setCustomizations(formatCustomizations(customisation_items))
    }
  }, [productPayload])

  useEffect(() => {
    const mappings = createCustomizationAndGroupMapping(customizations)
    setCustomizationToGroupMap(mappings.customizationToGroupMap)
    // setGroupToCustomizationMap(mappings.groupToCustomizationMap)
  }, [customizationGroups, customizations])

  useEffect(() => {
    const initializeCustomizationState = () => {
      const minSeq = findMinMaxSeq(customizationGroups).minSeq
      const firstGroup = customizationGroups.find((group) => group.seq === minSeq)
      const customization_state = { firstGroup }

      const processGroup = (id) => {
        const group = customizationGroups.find((item) => item.id === id)
        if (!group) return
        const groupId = group.id
        const groupName = group.name
        const isMandatory = group.minQuantity > 0

        customization_state[groupId] = {
          id: groupId,
          name: groupName,
          seq: group.seq,
          options: [],
          selected: [],
          childs: [],
          isMandatory,
          type: group.maxQuantity > 1 ? 'Checkbox' : 'Radio',
        }

        const childCustomizations = customizations.filter((customization) => customization.parent === groupId)

        customization_state[groupId].options = childCustomizations
        customization_state[groupId].selected = findSelectedCustomizationForGroup(
          customization_state[groupId],
          childCustomizations,
        )

        let childGroups =
          customization_state[groupId].selected[0]?.id != undefined
            ? customizationToGroupMap[customization_state[groupId].selected[0]?.id]
            : []
        customization_state[groupId].childs = childGroups

        if (childGroups) {
          for (const childGroup of childGroups) {
            processGroup(childGroup)
          }
        }
      }

      if (firstGroup) {
        processGroup(firstGroup.id)
        setCustomizationState(customization_state)
      }
    }

    if (!isEditFlow) {
      initializeCustomizationState()
    }
  }, [customizationGroups, customizations, customizationToGroupMap])

  const findSelectedCustomizationForGroup = (group, childCustomizations) => {
    if (!group.isMandatory) return []
    let selected_groups = []
    let defaultCustomization = childCustomizations.filter(
      (customization) => customization.isDefault && customization.inStock,
    )

    if (defaultCustomization.length) {
      selected_groups = defaultCustomization
    } else {
      const x = childCustomizations.find((customization) => customization.inStock)
      selected_groups = x ? [x] : []
    }

    let is_item_out_of_stock = true
    if (selected_groups.length) is_item_out_of_stock = false

    setItemOutOfStock(is_item_out_of_stock)
    return selected_groups
  }

  const processGroup = (groupId, updatedCustomizationState1, selectedGroup, selectedOption) => {
    const currentGroup = customizationGroups.find((item) => item.id === groupId)
    if (!currentGroup) return

    const groupName = currentGroup.name
    const isMandatory = currentGroup.minQuantity > 0

    const currentGroupOldState = updatedCustomizationState1[currentGroup.id]

    updatedCustomizationState1[groupId] = {
      id: groupId,
      name: groupName,
      seq: currentGroup.seq,
      options: [],
      selected: [],
      childs: [],
      isMandatory,
      type: 'Checkbox',
    }
    updatedCustomizationState1[groupId].options = []

    const childCustomizations = customizations.filter((customization) => customization.parent === groupId)
    updatedCustomizationState1[groupId].options = childCustomizations

    let childGroups = []
    if (currentGroup.id === selectedGroup.id) {
      let new_selected_options = []
      if (!isMandatory && currentGroupOldState.selected.find((optn) => optn.id == selectedOption.id)) {
        new_selected_options = [...currentGroupOldState['selected']].filter((item) => item.id != selectedOption.id)
        updatedCustomizationState1[groupId].selected = new_selected_options
      } else {
        if (currentGroup.maxQuantity === 1) {
          childGroups = customizationToGroupMap[selectedOption.id]
          updatedCustomizationState1[groupId].selected = [selectedOption]
        } else {
          if (currentGroup.maxQuantity > 1 && currentGroupOldState.selected.length < currentGroup.maxQuantity) {
            new_selected_options = [...currentGroupOldState['selected'], selectedOption]
            updatedCustomizationState1[groupId].selected = new_selected_options
          } else {
            updatedCustomizationState1[groupId].selected = currentGroupOldState.selected
          }
        }
      }
    } else {
      const groupSelectedOption = currentGroupOldState.selected
      updatedCustomizationState1[groupId].selected = groupSelectedOption
      if (groupSelectedOption.length > 0) {
        childGroups = customizationToGroupMap[groupSelectedOption[0].id]
      }
    }

    if (childGroups.length) {
      updatedCustomizationState1[groupId].childs = childGroups
      for (const childGroup of childGroups) {
        processGroup(childGroup, updatedCustomizationState1, selectedGroup, selectedOption)
      }
    }
  }

  const handleCustomizationChange = (event, group, selectedOption) => {
    let updatedCustomizationState1 = { ...customization_state }

    const groupId = group.id
    const isSelected = event.target.checked

    if (group.type === 'Checkbox') {
      if (isSelected) {
        updatedCustomizationState1[groupId].selected.push(selectedOption)
      } else {
        updatedCustomizationState1[groupId].selected = updatedCustomizationState1[groupId].selected.filter(
          (item) => item.id !== selectedOption.id,
        )
      }
    } else if (group.type === 'Radio') {
      updatedCustomizationState1[groupId].selected = [selectedOption]
    }

    const childGroups = customizationToGroupMap[selectedOption.id]
    updatedCustomizationState1[groupId].childs = childGroups

    if (childGroups) {
      for (const childGroup of childGroups) {
        processGroup(childGroup, updatedCustomizationState1, group, selectedOption)
      }
    }

    setCustomizationState(updatedCustomizationState1)
  }

  const renderGroups = (groups, customization_state) => {
    if (!groups || groups.length === 0) return null

    const sortedGroups = [...groups].sort((a, b) => a.seq - b.seq)

    return sortedGroups.map((group) => {
      const {
        id,
        name,
        // seq,
        options,
        selected,
        isMandatory,
        type,
        childs,
      } = customization_state[group.id] || {}

      return (
        <div key={id} className={classes.customizationGroup}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${id}-content`}
              id={`panel-${id}-header`}
            >
              <Typography variant="subtitle1">
                {name} (Choose {isMandatory ? 'at least ' : ''}
                {group.minQuantity} {group.minQuantity > 1 ? 'options' : 'option'})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {options?.map((option) => (
                  <Grid item key={option.id}>
                    <FormControlLabel
                      control={
                        type === 'Checkbox' ? (
                          <Checkbox
                            checked={!!selected.find((selectedOption) => selectedOption.id === option.id)}
                            onChange={(e) => handleCustomizationChange(e, group, option)}
                          />
                        ) : (
                          <Radio
                            checked={!!selected.find((selectedOption) => selectedOption.id === option.id)}
                            onChange={(e) => handleCustomizationChange(e, group, option)}
                          />
                        )
                      }
                      label={
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>{option.name}</Grid>
                          {option.price > 0 && (
                            <Grid item>
                              <CurrencyRupeeIcon className={classes.currencyIcon} />
                              {option.price}
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          {childs && childs.length > 0 && renderGroups(childs, customization_state)}
        </div>
      )
    })
  }

  return <div>{renderGroups(customizationGroups, customization_state)}</div>
}

export default CustomizationRenderer
