import React from 'react'
import ReactGA from 'react-ga4'
import useStyles, {
  Wrapper,
  ItemDetailSection,
  MenuTitle,
  MenuItemPrice,
  MenuItemDescription,
  ButtonSection,
  MenuItemImageSection,
} from './style'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import VegIcon from '../../../../assets/images/veg.svg'
import NonVegIcon from '../../../../assets/images/nonveg.svg'
import no_image_found from '../../../../assets/images/no_image_found.png'
import { useHistory } from 'react-router-dom'
import { hasCustomizations } from '../../../application/product-list/product-details/utils'
import Loading from '../../../shared/loading/loading'

const MenuItem = (props) => {
  const classes = useStyles()
  const {
    productPayload,
    product,
    productId,
    price,
    handleAddToCart,
    setCustomizationModal,
    getProductDetails,
    productLoading,
  } = props
  const { descriptor, isVeg } = product

  const { name: product_name, short_desc: product_description, symbol } = descriptor
  const history = useHistory()

  const renderVegNonvegIcon = () => {
    const tags = product.tags
    let category = 'veg'

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].code === 'veg_nonveg') {
        category = tags[i].list[0].code
      }
    }

    if (category == 'veg') {
      return <img src={VegIcon} alt={'veg-icon'} className={classes.vegNonvegIcon} />
    } else {
      return <img src={NonVegIcon} alt={'nonveg-icon'} className={classes.vegNonvegIcon} />
    }
  }

  productPayload.item_details.tags.find((item) => item.code === 'custom_group')

  const handleButtonClick = (productId, getProductDetails, setCustomizationModal) => {
    getProductDetails(productId)
    setCustomizationModal(true)
    ReactGA.event({
      category: 'Brand',
      action: 'Click',
      label: 'Customise',
    })
  }

  const handleAddToCartClick = (
    productId,
    productPayload,
    getProductDetails,
    setCustomizationModal,
    handleAddToCart,
  ) => {
    ReactGA.event({
      category: 'Brand',
      action: 'Click',
      label: 'Add To Card',
    })

    if (hasCustomizations(productPayload)) {
      getProductDetails(productId)
      setCustomizationModal(true)
    } else {
      getProductDetails(productId).then((data) => {
        handleAddToCart(data, true)
      })
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={9.5} lg={9.5} xl={9.5}>
        <Wrapper>
          <ItemDetailSection>
            <MenuTitle>{product_name}</MenuTitle>
            <MenuItemPrice>
              {`₹${
                Number.isInteger(Number(price?.value))
                  ? Number(price?.value).toFixed(2)
                  : Number(price?.value).toFixed(2)
              }`}
            </MenuItemPrice>
            <MenuItemDescription>{product_description}</MenuItemDescription>
          </ItemDetailSection>

          <ButtonSection>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                handleAddToCartClick(
                  productId,
                  productPayload,
                  getProductDetails,
                  setCustomizationModal,
                  handleAddToCart,
                )
              }
              disabled={productLoading}
            >
              {productLoading === productId ? <Loading height="8px" width="8px" /> : 'Add to cart'}
            </Button>

            {hasCustomizations(productPayload) && (
              <Button
                fullWidth
                variant="outlined"
                onClick={() => handleButtonClick(productId, getProductDetails, setCustomizationModal)}
              >
                Customise
              </Button>
            )}
          </ButtonSection>
        </Wrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
        <MenuItemImageSection>
          <img
            className="item-image"
            src={symbol ? symbol : no_image_found}
            alt={`item-ind-${productId}`}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/products?productId=${productId}`)}
          />
          {renderVegNonvegIcon(isVeg)}
        </MenuItemImageSection>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box component={'div'} className={classes.divider} />
      </Grid>
    </Grid>
  )
}

export default MenuItem
