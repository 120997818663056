import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import useGet from 'hooks/useGet'
import Tab from 'components/Tab'
import AllBlogs from 'components/hpLayout/blogsSection'
import CategoryWiseBlogs from 'components/hpLayout/categoryBlogs'
import FestiveSection from 'components/hpLayout/festiveSection'
import { Container, MainHeading } from 'styles/Blogs'

const Blogs = () => {
  const [value, setValue] = useState('all')
  const { data: blogsdata, refetch: fetchBlogs } = useGet(
    'allBlogs',
    value === 'all' ? `/api/blogs` : `/api/blogs?category=${value}`,
  )

  const handleChange = (event, newValue) => {
    event
    setValue(newValue)
  }

  useEffect(() => {
    fetchBlogs()
  }, [value])
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Explore Our Latest Blogs',
    })
  }, [])

  const items = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Agriculture',
      value: 'agriculture',
    },
    {
      label: 'Products',
      value: 'products',
    },
    {
      label: 'Lifestyle',
      value: 'lifestyle',
    },
    {
      label: 'Culture',
      value: 'culture',
    },
    {
      label: 'Spices',
      value: 'spices',
    },
    {
      label: 'Places',
      value: 'places',
    },
  ]

  const panelItems = [
    {
      id: 'all',
      data: <AllBlogs data={blogsdata?.data} />,
    },
    {
      id: 'agriculture',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
    {
      id: 'products',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
    {
      id: 'lifestyle',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
    {
      id: 'culture',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
    {
      id: 'spices',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
    {
      id: 'places',
      data: <CategoryWiseBlogs data={blogsdata?.data} />,
    },
  ]

  return (
    <Container>
      <MainHeading>Explore Our Latest Blogs</MainHeading>
      <Tab value={value} handleChange={handleChange} items={items} panelItems={panelItems} isCenteredPage />
      <FestiveSection />
    </Container>
  )
}

export default Blogs
