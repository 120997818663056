
import React from 'react';
import { BannerWrapper, ImageWrapper, BreadcrumbWrapper, BannerTitle } from 'styles/FooterLinks/BannerSection';

const BannerSection = ({ title, bannerImage }) => {
  return (
    <BannerWrapper>
      <ImageWrapper>
        <img src={bannerImage} alt="banner-image" />
      </ImageWrapper>
      <BreadcrumbWrapper>
        <BannerTitle>{title}</BannerTitle>
      </BreadcrumbWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
