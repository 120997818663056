import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useGet from 'hooks/useGet'
import moment from 'moment'
import Spinner from 'components/Loader'
import EllipseIcon from 'assets/svg/HPSvgs/EllipseIcon'

import {
  Container,
  HeadingWrapper,
  DateAndCategorySection,
  DateWrapper,
  CategoryWrapper,
  BlogDetailSection,
  ImageWrapper,
  Title,
} from 'styles/hpLayout/blogDetails'

const BlogDetailPage = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  const { data: blogData, refetch: fetchBlog } = useGet('Blog', `/api/blogs/${id}`)

  useEffect(() => {
    if (id) {
      setLoading(true)
      fetchBlog().finally(() => setLoading(false))
    }
  }, [id, fetchBlog])

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <HeadingWrapper>
            <DateAndCategorySection>
              <DateWrapper>{moment(blogData?.data?.updatedAt).format('MMMM Do, YYYY')}</DateWrapper>
              <EllipseIcon />
              <CategoryWrapper>{blogData?.data?.category}</CategoryWrapper>
            </DateAndCategorySection>
          </HeadingWrapper>
          <BlogDetailSection>
            <Title>{blogData?.data?.title}</Title>
            <ImageWrapper>
              <img src={blogData?.data?.bannerImage} alt={blogData?.data?.title} />
            </ImageWrapper>
            <div dangerouslySetInnerHTML={{ __html: blogData?.data?.content || '' }}></div>
          </BlogDetailSection>
        </>
      )}
    </Container>
  )
}

export default BlogDetailPage
