import styled from 'styled-components'
import {theme} from "styles/Theme/theme"

export const ModalContent = styled.div`
  .modal-component {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
    }
  }
`
export const HeaderSection = styled.div`
  .modal_header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .buy_now {
    display: flex;
    background: ${theme.HPSECONDARYCOLOR};
    color: ${theme.SECONDARYTEXTCOLOR};
    justify-content: space-between;
    padding: 16px;
    font-size: 20px;
  }
  svg {
    cursor: pointer;
  }
`
