import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import NotFoundImage from 'assets/images/HpImages/page-not-found.png'
import { Container, Wrapper, ImageSection, Title, SubTitle, ButtonWrapper } from 'styles/PageNotFound'

const PageNotFound = () => {
  const history = useHistory()
  return (
    <Container>
      <Wrapper>
        <ImageSection ImageSection className="py-4">
          <img src={NotFoundImage} alt="404_not_found" />
        </ImageSection>
        <Title>404 Not Found</Title>
        <SubTitle>
          The page you are looking for does not exist! <br /> Return to home page
        </SubTitle>
        <ButtonWrapper>
          <Button variant="contained" onClick={() => history.push('/')}>
            Continue Shopping
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  )
}

export default PageNotFound
