import { makeStyles } from '@mui/styles'
// import palette from "../../utils/Theme/palette";
import styled from 'styled-components'

const useStyles = makeStyles({
  ordersContainer: {
    padding: '45px 40px',
  },
  orderHistoryTypo: {
    fontSize: '32px !important',
  },
  tabTanelContainer: {
    marginTop: '16px !important',
  },
  '@media (max-width: 450px)': {
    ordersContainer: {
      padding: '16px',
    },
    orderHistoryTypo: {
      fontSize: '24px !important',
    },
  },
})

export default useStyles

export const TabsWrapper = styled.div`
  .tabConatianer {
    @media (max-width: 450px) {
      padding-top: 14px !important;
    }
  }
`
