import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ReviewContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  max-width: 678px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: 100%;
  }
`
export const ReviewContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ReviewComments = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.HPTEXTCOLORLIGHT};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const ReviewerDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const ReviewerName = styled.h6`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.HPTEXTCOLORLIGHT};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`
export const ReviewerLocation = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.HPTEXTCOLORTERTIONARY};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`
