import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import NoDataFound from 'views/EmptyData'
import IssueImage from 'assets/images/HpImages/order-image.png'
import {
  Container,
  Wrapper,
  MainWrapper,
  OrderDetailSection,
  ImageWrapper,
  OrderDetails,
  OrderIdWrapper,
  OrderId,
  IssueCountWrapper,
  CountTitle,
  IssueCount,
  ButtonWrapper,
} from 'styles/ComplaintsCard'

const ComplaintsCard = (issueDetails) => {
  const history = useHistory()

  if (issueDetails?.issueDetails?.length === 0) {
    return (
      <Container>
        <NoDataFound />
      </Container>
    )
  }

  return (
    <>
      {issueDetails?.issueDetails?.map((item, index) => {
        return (
          <Container key={index}>
            <Wrapper>
              <MainWrapper>
                <OrderDetailSection>
                  <ImageWrapper>
                    <img src={IssueImage} />
                  </ImageWrapper>
                  <OrderDetails>
                    <OrderIdWrapper>
                      <OrderId>Order ID : </OrderId>
                      <OrderId>{item?.orderId}</OrderId>
                    </OrderIdWrapper>
                    <IssueCountWrapper>
                      <CountTitle>No. of Issues Raised </CountTitle>
                      <IssueCount>{item?.no_of_issues}</IssueCount>
                    </IssueCountWrapper>
                  </OrderDetails>
                </OrderDetailSection>
                <ButtonWrapper>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      history.push(`/profile/complaints/${item?.orderId}`)

                      ReactGA.event({
                        category: 'Sidebar',
                        action: 'Click',
                        label: 'View Details',
                      })
                    }}
                  >
                    View Details
                  </Button>
                </ButtonWrapper>
              </MainWrapper>
            </Wrapper>
          </Container>
        )
      })}
    </>
  )
}

export default ComplaintsCard
