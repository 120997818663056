import React from 'react'
import FooterApi from 'utils/FooterApi'
import BannerImage from 'assets/images/HpImages/banner-image.png'
import BannerSection from 'views/BannerSection'
import { MainWrapper, AboutUsWrapper, ContentWrapper } from 'styles/FooterLinks/AboutUsPage'

const ShippingPolicy = () => {
  const { data } = FooterApi('shippingpolicy')
  return (
    <MainWrapper>
      <BannerSection title="Shipping Policy" bannerImage={BannerImage}/>
      <AboutUsWrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: data?.shippingpolicy }} />
        </ContentWrapper>
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default ShippingPolicy
