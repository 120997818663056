import Natural from 'assets/svg/HpNaturalIcon'
import Hygine from 'assets/svg/HpHygine'
import Truck from 'assets/svg/HpTruck'
import Local from 'assets/svg/HpLocalIcon'

export const featuresData = [
  {
    icon: <Natural />,
    title: 'NATURAL PRODUCTS',
    description:
      'HimIra’s products are crafted from natural ingredients directly sourced from the pristine Himalayas. We ensure no chemicals or harmful additives are used, keeping our products as pure and unprocessed as possible. Every product brings you the essence of nature, offering authenticity and natural goodness, designed to enrich your everyday life with the richness of the Himalayas.',
  },
  {
    icon: <Local />,
    title: 'LOCALLY GROWN',
    description:
      'All of our products are locally grown in Himachal Pradesh, ensuring freshness and sustainability. We work closely with farmers and artisans, supporting local communities and promoting eco-friendly farming methods. By choosing HimIra, you’re not only getting fresh, quality products but also contributing to the growth of local communities and preserving traditional Himalayan agricultural practices.',
  },
  {
    icon: <Hygine />,
    title: 'HYGIENIC STORING',
    description:
      'We prioritize hygienic storage for all our products, ensuring they remain fresh and maintain their natural qualities. From the moment we source ingredients to the time you receive them, our strict hygiene practices preserve their original purity. You can trust that HimIra’s products are handled with care, offering the highest standards of safety and quality at all times.',
  },
  {
    icon: <Truck />,
    title: 'DIRECT DISPATCHED',
    description:
      'HimIra ensures quick and direct dispatch of all products, with minimal handling to maintain quality. By reducing unnecessary steps in the supply chain, we guarantee freshness. From the fields of Himachal Pradesh to your home, every product reaches you swiftly, retaining its natural goodness and quality, offering you a true Himalayan experience with every delivery.',
  },
]
