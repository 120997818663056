import Slider from 'react-slick'
import Review from 'components/hpLayout/testimonials/review'
import { data } from 'components/hpLayout/testimonials/data'
import PreviousArrow from 'assets/svg/PreviousArrow'
import NextArrow from 'assets/svg/NextArrow'
import { ReviewsWrapper, MainHeading, ReviewsSection, ReviewsContainer, IconButton } from 'styles/hpLayout/testimonials'

const Testimonials = () => {
  const sliderSettings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: false,
        },
      },
    ],
    nextArrow: (
      <IconButton>
        <NextArrow />
      </IconButton>
    ),
    prevArrow: (
      <IconButton>
        <PreviousArrow />
      </IconButton>
    ),
  }
  return (
    <ReviewsWrapper>
      <MainHeading>Testimonials</MainHeading>
      <ReviewsSection>
        <Slider {...sliderSettings}>
          {data?.map((item, index) => (
            <ReviewsContainer key={index}>
              <Review
                reviewerImage={item?.reviewerImage}
                reviewerName={item?.reviewerName}
                comments={item?.comments}
                location={item?.reviwerLocation}
              />
            </ReviewsContainer>
          ))}
        </Slider>
      </ReviewsSection>
    </ReviewsWrapper>
  )
}

export default Testimonials
