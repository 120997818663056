import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { Button } from '@mui/material'
import { TextHeading, NoResultWrapper, HeadingWrapper, SubHeading } from '../../../styles/no-result-found'
import EmptyCart from 'assets/images/HpImages/empty-cart.png'
import NoOrder from 'assets/images/HpImages/no-orders.png'

export const emptyCartScreen = () => {
  const history = useHistory()

  return (
    <NoResultWrapper>
      <img src={EmptyCart} alt="emptycart" />
      <TextHeading>Your Cart is Empty. Please add items</TextHeading>
      <HeadingWrapper>
        <SubHeading>Explore our wide selection and find something you like</SubHeading>
      </HeadingWrapper>
      <Button
        variant="contained"
        onClick={() => {
          history.push('/')
          ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Continue Shopping',
          })
        }}
      >
        Continue Shopping
      </Button>
    </NoResultWrapper>
  )
}

export const EmptyOrders = () => {
  const history = useHistory()

  return (
    <NoResultWrapper>
      <img src={NoOrder} alt="emptycart" />
      <TextHeading>No Order is Available, Please add items</TextHeading>
      <HeadingWrapper>
        <SubHeading>Explore our wide selection and find something you like</SubHeading>
      </HeadingWrapper>
      <Button
        variant="contained"
        onClick={() => {
          history.push('/')
          ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Continue Shopping',
          })
        }}
      >
        Continue Shopping
      </Button>
    </NoResultWrapper>
  )
}

export const EmptyCompletedOrders = () => {
  const history = useHistory()

  return (
    <NoResultWrapper>
      <img src={NoOrder} alt="emptycart" />
      <TextHeading>No Order is Completed.</TextHeading>
      <HeadingWrapper>
        <SubHeading>Explore our wide selection and find something you like</SubHeading>
      </HeadingWrapper>
      <Button
        variant="contained"
        onClick={() => {
          history.push('/')
          ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Continue Shopping',
          })
        }}
      >
        Continue Shopping
      </Button>
    </NoResultWrapper>
  )
}

export const EmptyCancelledOrders = () => {
  const history = useHistory()

  return (
    <NoResultWrapper>
      <img src={NoOrder} alt="emptycart" />
      <TextHeading>No Order is Cancelled</TextHeading>
      <HeadingWrapper>
        <SubHeading>Explore our wide selection and find something you like</SubHeading>
      </HeadingWrapper>
      <Button
        variant="contained"
        onClick={() => {
          history.push('/')
          ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Continue Shopping',
          })
        }}
      >
        Continue Shopping
      </Button>
    </NoResultWrapper>
  )
}
