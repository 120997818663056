import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      stroke="#686868"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m15.583 8.394-.07-.937c-.063-.858-.805-1.523-1.698-1.523h-1.821M5.5 17.416H4.454c-.989 0-1.77-.807-1.7-1.757l.609-8.202c.064-.858.805-1.523 1.7-1.523h1.82m0 0v-1.64c0-1.36 1.144-2.461 2.555-2.461 1.412 0 2.556 1.102 2.556 2.46v1.64m-5.111 0h5.11m3.59 7.817a1.833 1.833 0 0 1-3.666 0m-1.834 5.5h7.334c1.012 0 1.833-.821 1.833-1.834v-4.583c0-1.013-.82-1.833-1.833-1.833h-7.334c-1.012 0-1.833.82-1.833 1.833v4.583c0 1.013.82 1.834 1.833 1.834Z"
    />
  </svg>
)
export default SvgComponent
