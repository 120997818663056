import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4' // Install with npm install react-ga4

const TrackingComponent = () => {
  const location = useLocation()

  useEffect(() => {
    const TrackingId = process.env.REACT_APP_GOOGLE_ANALYTIC_TRAKING_ID
    if (TrackingId) {
      ReactGA.initialize(TrackingId)
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
    }
  }, [location])

  return null
}

export default TrackingComponent
