import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`
export const Wrapper = styled.form`
  display: flex;
  max-width: 700px;
  min-width: 700px;
  width: 100%;
  gap: 24px;
  @media (max-width: 800px) {
    max-width: 600px;
    min-width: 400px;
  }
  @media (max-width: 500px) {
    max-width: 450px;
    min-width: 300px;
    gap: 11px;
  }
`
export const InputWrapper = styled.div`
  width: 100%;
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e43030;
  padding: 2px 0;
  text-align: start;
`
export const ButtonSection = styled.div`
  button {
    text-transform: none;
    height: 56px;
  }
`
