import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      fill="#686868"
      d="M11.003.687c-.918 0-1.716.613-2.036 1.365L.94 17.957c-.394.896-.303 1.778.116 2.395.42.617 1.133.96 1.877.96H19.04c.744 0 1.46-.341 1.879-.96.656-.966.266-2.087.116-2.395L13.006 2.052c-.453-.845-1.085-1.365-2.003-1.365Zm-.006 1.386c.534 0 .687.417.765.562l8.025 15.905c.214.517.14.827-.005 1.041-.147.215-.416.357-.742.357H2.932c-.326 0-.589-.14-.735-.357-.146-.214-.204-.58-.011-1.046l8.03-15.9c.07-.12.247-.562.781-.562Zm.006 5.665a.687.687 0 0 0-.692.684v4.816a.688.688 0 1 0 1.377 0V8.422a.687.687 0 0 0-.685-.684ZM11 15.446a1.031 1.031 0 1 0 0 2.063 1.031 1.031 0 0 0 0-2.063Z"
    />
  </svg>
)
export default SvgComponent
