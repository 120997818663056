import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import Tab from '../../components/Tab'
import { MainContainer, Title } from '../../styles/myOrder'
import OnGoingOrders from 'components/orders/onGoingOrders/onGoingOrders'
import CompletedOrders from 'components/orders/completedOrders/completedOrders'
import CancelledOrders from 'components/orders/cancelledOrders/cancelledOrders'

const MyOrder = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    event
    setValue(newValue)
  }

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'My Orders' })
  }, [])

  const items = [
    {
      label: 'Active',
      value: 0,
    },
    {
      label: 'Cancelled',
      value: 1,
    },
    {
      label: 'Completed',
      value: 2,
    },
  ]
  //Dynamic panel items data
  const panelItems = [
    {
      id: 0,
      data: <OnGoingOrders />,
    },
    {
      id: 1,
      data: <CancelledOrders />,
    },
    {
      id: 2,
      data: <CompletedOrders />,
    },
  ]

  return (
    <MainContainer>
      <Title>My Orders</Title>
      <Tab value={value} handleChange={handleChange} items={items} panelItems={panelItems} />
    </MainContainer>
  )
}

export default MyOrder
