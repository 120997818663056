import React from 'react'
import { OutOfStockWrapper, OutOfStockBackgroundWrapper, OutOfStockTitle } from 'styles/OutOfStock'
import BackgroundImage from 'assets/images/stock-background.png'

const OutOfStock = () => (
  <OutOfStockWrapper>
    <OutOfStockBackgroundWrapper>
      <img src={BackgroundImage} alt="background" />
    </OutOfStockBackgroundWrapper>
    <OutOfStockTitle>OUT OF STOCK</OutOfStockTitle>
  </OutOfStockWrapper>
)

export default OutOfStock
