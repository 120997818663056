import { useHistory } from 'react-router-dom'
import CustomButton from 'components/customButton'
import ForwardIcon from 'assets/svg/HPSvgs/ForwardIcon'
import {
  Container,
  MobileMainHeading,
  ProductImageSection,
  DescriptionSection,
  ContentSection,
  MainHeading,
  ProccessDescription,
  ButtonWrapper,
} from 'styles/hpLayout/productProcessingSection'

const ProductProcessingSection = () => {
  const history = useHistory()

  return (
    <Container>
      <MobileMainHeading>How we make it</MobileMainHeading>
      <ProductImageSection>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/e3Zs4Sj-OUI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </ProductImageSection>
      <DescriptionSection>
        <ContentSection>
          <MainHeading>How we make it</MainHeading>
          <ProccessDescription>
            At HimIra, we carefully source the freshest, all-natural spices directly from the Himalayas. Our process
            ensures that every spice retains its rich flavor and aroma, from handpicking to careful packaging. With a
            commitment to purity and quality, we deliver authentic Himalayan spices to your kitchen, bringing the taste
            of nature straight to your home in every order.
          </ProccessDescription>
        </ContentSection>
        <ButtonWrapper>
          <CustomButton
            label="Shop Now"
            variant="contained1"
            icon={<ForwardIcon />}
            onClick={() => {
              history.push(`categories`)
            }}
          />
        </ButtonWrapper>
      </DescriptionSection>
    </Container>
  )
}

export default ProductProcessingSection
