import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={19} fill="none" {...props}>
    <path
      fill="#D83232"
      d="M14.248 5.449a.75.75 0 0 0-.75.75v8.393a1.44 1.44 0 0 1-1.507 1.357H6.006a1.44 1.44 0 0 1-1.508-1.357V6.2a.75.75 0 0 0-1.5 0v8.393a2.939 2.939 0 0 0 3.008 2.857h5.985a2.939 2.939 0 0 0 3.007-2.857V6.2a.75.75 0 0 0-.75-.75Zm.75-2.25h-3v-1.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v1.5h-3a.75.75 0 1 0 0 1.5h12a.75.75 0 1 0 0-1.5Zm-7.5 0v-.75h3v.75h-3Z"
    />
    <path
      fill="#D83232"
      d="M8.248 12.95V7.7a.75.75 0 0 0-1.5 0v5.25a.75.75 0 0 0 1.5 0Zm3 0V7.7a.75.75 0 1 0-1.5 0v5.25a.75.75 0 0 0 1.5 0Z"
    />
  </svg>
)
export default SvgComponent
