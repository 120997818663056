import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import Grid from '@mui/material/Grid'
import useCancellablePromise from 'api/cancelRequest'
import { getAllOutletsFromCategoryAndLocationRequest } from 'api/brand.api'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { SearchContext } from 'context/searchContext'
import { LocationContext } from 'context/locationContext'
import { useTheme } from 'context/newThemeContext'
import Spinner from 'components/Loader'
import { BrandItemContainer, StoreCardContainer } from 'components/category/brands/style'
import StoreCards from 'views/StoresCard'

const ElectronicBrands = () => {
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  const [brands, setBrands] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useContext(ToastContext)
  const { latitude, longitude } = useContext(LocationContext)
  const { cancellablePromise } = useCancellablePromise()
  const locationData = useLocation()

  useEffect(() => {
    getAllBrands()
  }, [locationData, deliveryAddressLocation, latitude, longitude])

  const getAllBrands = async () => {
    setIsLoading(true)
    try {
      const reqParams = {
        lat: latitude,
        lng: longitude,
      }
      const data = await cancellablePromise(getAllOutletsFromCategoryAndLocationRequest(reqParams))
      setBrands(data.data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message || 'An error occurred',
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const { theme } = useTheme()

  var settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          autoplay: false,
        },
      },
    ],
  }

  return (
    <BrandItemContainer theme={theme}>
      <div>
        {isLoading ? (
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <Spinner />
          </Grid>
        ) : (
          <>
            {brands?.length > 0 ? (
              <StoreCardContainer theme={theme}>
                <Slider {...settings}>
                  {brands?.map((item, ind) => (
                    <div key={`sub-cat-item-${ind}`}>
                      <StoreCards data={item} />
                    </div>
                  ))}
                </Slider>
              </StoreCardContainer>
            ) : (
              <div>No stores available near you</div>
            )}
          </>
        )}
      </div>
    </BrandItemContainer>
  )
}

export default ElectronicBrands
