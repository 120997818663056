import React from 'react'
import Slider from 'react-slick'
import { featuresData } from 'components/hpLayout/FeatureSection/data'
import qualityBackground from 'assets/images/HpImages/quality-background.png'
import {
  MainContainer,
  FeatureBackgroungWrapper,
  PaperContainer,
  MainHeading,
  WebContentGrid,
  ContentBlock,
  Icon,
  ContentSection,
  Title,
  Description,
  MobileContentGrid,
} from 'styles/hpLayout/featureSection'

const FeaturesSection = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <MainContainer>
      <FeatureBackgroungWrapper>
        <img src={qualityBackground} alt="image" />
      </FeatureBackgroungWrapper>
      <PaperContainer>
        <MainHeading>WHY HIMIRA</MainHeading>
        <WebContentGrid>
          {featuresData?.map((item, index) => (
            <ContentBlock key={index}>
              <Icon>{item?.icon}</Icon>
              <ContentSection>
                <Title>{item?.title}</Title>
                <Description>{item?.description}</Description>
              </ContentSection>
            </ContentBlock>
          ))}
        </WebContentGrid>
        <MobileContentGrid className="features-slider">
          <Slider {...sliderSettings}>
            {featuresData?.map((item, index) => (
              <ContentBlock key={index}>
                <Icon>{item?.icon}</Icon>
                <ContentSection>
                  <Title>{item?.title}</Title>
                  <Description>{item?.description}</Description>
                </ContentSection>
              </ContentBlock>
            ))}
          </Slider>
        </MobileContentGrid>
      </PaperContainer>
    </MainContainer>
  )
}

export default FeaturesSection
