import styled from 'styled-components'

export const Container = styled.div`
  margin: 100px 100px 0 100px;
  @media (max-width: 450px) {
    margin: 16px;
    margin-top: 80px;
  }
`
export const HeadingWrapper = styled.div``
export const DateAndCategorySection = styled.div`
  display: flex;
  gap: 41px;
  justify-content: center;
  align-items: center;
`
export const DateWrapper = styled.div``
export const CategoryWrapper = styled.div`
  color: #ebb935;
`
export const BlogDetailSection = styled.div`
  margin-top: 56px;
  @media (max-width: 450px) {
    margin-top: 20px;
  }
`
export const Title = styled.h3`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 51.2px;
  text-align: center;
  margin-bottom: 50px !important;
  @media (max-width: 450px) {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px !important;
    line-height: 30px;
  }
`
export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: 563px;
    border-radius: 15px;
    object-fit: contain;
    margin-bottom: 60px;
  }
  @media (max-width: 450px) {
    img {
      height: auto;
      margin-bottom: 30px;
    }
  }
`
