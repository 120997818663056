import React from 'react'
import NoDataImage from 'assets/images/HpImages/no-data-found.png'
import { MainWrapper, Wrapper, IconWrapper, ContentWrapper, MainHeading } from 'styles/NoDataFound'

const NoDataFound = () => (
  <MainWrapper>
    <Wrapper>
      <IconWrapper>
        <img src={NoDataImage} alt="no-data" />
      </IconWrapper>
      <ContentWrapper>
        <MainHeading>No Data Found</MainHeading>
      </ContentWrapper>
    </Wrapper>
  </MainWrapper>
)

export default NoDataFound
