import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" {...props}>
    <rect width={36} height={36} fill="#fff" fillOpacity={0.1} rx={8} />
    <rect width={35} height={35} x={0.5} y={0.5} stroke="#fff" strokeOpacity={0.2} rx={7.5} />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M17.995 25.49h-.005c-.603-.003-5.938-.052-7.45-.46a3.09 3.09 0 0 1-2.175-2.174c-.396-1.485-.37-4.344-.368-4.572-.002-.227-.028-3.11.367-4.607v-.004a3.156 3.156 0 0 1 2.173-2.188l.008-.002c1.495-.393 6.84-.44 7.445-.445H18c.606.004 5.955.053 7.453.462a3.09 3.09 0 0 1 2.172 2.17c.41 1.499.372 4.387.368 4.631.003.24.027 3.073-.366 4.566l-.001.004a3.091 3.091 0 0 1-2.176 2.174l-.005.001c-1.494.393-6.84.44-7.444.445h-.006ZM9.874 14.079c-.347 1.322-.314 4.167-.314 4.196v.02c-.01.79.026 3.075.314 4.157.14.519.551.93 1.073 1.07 1.115.301 5.46.396 7.048.407 1.593-.01 5.944-.103 7.051-.393.52-.14.93-.55 1.07-1.069.29-1.098.325-3.372.315-4.156v-.025c.014-.799-.014-3.11-.313-4.202v-.003a1.524 1.524 0 0 0-1.074-1.072c-1.104-.302-5.457-.396-7.049-.407-1.59.01-5.938.103-7.049.392a1.567 1.567 0 0 0-1.072 1.085Zm6.13 7.35V15.1l5.469 3.165-5.469 3.163Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 11h20v20H8z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
