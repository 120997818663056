import React from 'react'
import { StyledButton } from 'styles/customButton'

const CustomButton = ({ label, variant, type, icon, disabled, onClick, ...rest }) => (
  <StyledButton variant={variant} type={type} disabled={disabled} onClick={onClick} {...rest}>
    {label}
    {icon}
  </StyledButton>
)
export default CustomButton
