import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loader: {
    textAlign: 'center',
    marginTop: '24px',
  },
})

export default useStyles
