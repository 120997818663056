import React from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import leftLeaf from 'assets/images/HpImages/leftLeaf-image.png'
import rightLeaf from 'assets/images/HpImages/rightLeaf-image.png'
import HpNextIcon from 'assets/svg/HpNextIcon'
import HpPreviousIcon from 'assets/svg/HpPreviousIcon'
import {
  Container,
  Wrapper,
  LeftImageWrapper,
  RightImageWrapper,
  HeadingSection,
  MainHeading,
  ImageWrapper,
  ProductsContainer,
  IconButton,
  // MobileHeadingSection,
} from 'styles/hpLayout/productCard'
import Category1 from 'assets/images/HpImages/1.png'
import Category2 from 'assets/images/HpImages/2.png'
import Category3 from 'assets/images/HpImages/3.png'
import Category4 from 'assets/images/HpImages/4.png'
import Category5 from 'assets/images/HpImages/5.png'
import Category6 from 'assets/images/HpImages/6.png'
import Category7 from 'assets/images/HpImages/7.png'

const ProductCard = () => {
  const history = useHistory()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
    prevArrow: (
      <IconButton>
        <HpPreviousIcon />
      </IconButton>
    ),
    nextArrow: (
      <IconButton>
        <HpNextIcon />
      </IconButton>
    ),
  }

  const banners = [
    {
      src: Category1,
      value: 'Oil & Ghee',
    },
    {
      src: Category5,
      value: 'Pickles and Chutney',
    },
    {
      src: Category3,
      value: 'Tea and Coffee',
    },
    {
      src: Category6,
      value: 'Salt, Sugar and Jaggery',
    },
    {
      src: Category2,
      value: 'Tinned and Processed Food',
    },
    {
      src: Category4,
      value: 'Snacks and Namkeen',
    },
    {
      src: Category7,
      value: 'Speciality Care',
    },
  ]

  return (
    <Container>
      <Wrapper>
        <LeftImageWrapper>
          <img src={leftLeaf} alt="image" />
        </LeftImageWrapper>
        <HeadingSection>
          <MainHeading>Categories</MainHeading>
        </HeadingSection>
        <RightImageWrapper>
          <img src={rightLeaf} alt="image" />
        </RightImageWrapper>
      </Wrapper>
      {/* <MobileHeadingSection>
        <MainHeading>Categories</MainHeading>
      </MobileHeadingSection> */}
      <ProductsContainer>
        <Slider {...settings}>
          {banners?.map((banner, index) => (
            <div key={index}>
              <ImageWrapper>
                <img
                  src={banner.src}
                  alt={`banner-${index}`} // Add alt attribute for accessibility
                  onError={(e) => {
                    e.target.src =
                      'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                  }}
                  onClick={() => {
                    history.push(`/categories/${banner?.value}`)
                  }}
                />
              </ImageWrapper>
            </div>
          ))}
        </Slider>
      </ProductsContainer>
    </Container>
  )
}

export default ProductCard
