import { makeStyles } from '@mui/styles'
import palette from '../../../utils/Theme/palette'
import styled from 'styled-components'

const useStyles = makeStyles({
  outletDetailsContainer: {
    padding: '48px 54px 28px 54px !important',
  },
  outletDetailsHeaderContainer: {},
  outletDetailsCard: {
    boxShadow: 'none !important',
    background: '#F5F5F5 !important',
    display: 'flex',
    height: '300px !important',
    borderRadius: '16px !important',
    position: 'relative !important',
    width: 'max-content',
  },
  outletContactInfoCard: {
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 6px 13px 0px rgba(0, 0, 0, 0.05), 0px 24px 24px 0px rgba(0, 0, 0, 0.04), 0px 54px 33px 0px rgba(0, 0, 0, 0.03), 0px 97px 39px 0px rgba(0, 0, 0, 0.01), 0px 151px 42px 0px rgba(0, 0, 0, 0.00) !important',
    background: '#fff !important',
    // display: 'flex',
    borderRadius: '16px !important',
    position: 'relative !important',
    padding: '24px !important',
  },
  outletImage: {
    width: '100%',
  },
  detailsContainer: {
    marginTop: '21px !important',
  },
  descriptionTypo: {
    marginTop: '16px !important',
  },
  outletNameTypo: {
    marginTop: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
  },
  outletOpeningTimeTypo: {
    marginTop: '10px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
    color: '#4A4A4A',
    '& $isOpen': {
      color: `${palette.success.main} !important`,
      marginRight: '5px !important',
    },
  },
  isOpen: {},
  actionButtonContainer: {
    marginTop: '24px !important',
  },
  actionButton: {
    padding: '10px 56px 10px 54px !important',
    marginRight: '8px !important',
  },
  contactNumbers: {
    color: `${palette.success.main} !important`,
    marginTop: '8px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
  },
  directionTypo: {
    //   marginTop: '24px !important'
  },
  mapImage: {
    // width: '100% !important',
    // height: '375px !important',
    // marginTop: '12px !important',
  },
  seeAllOutletTypo: {
    marginTop: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
    cursor: 'pointer',
  },

  divider: {
    height: '1px',
    backgroundColor: '#E0E0E0 !important',
    marginTop: '24px',
    marginBottom: '24px',
  },
})

export default useStyles

export const Container = styled.div`
  padding: 48px 54px 28px 54px;
  @media (max-width: 450px) {
    padding: 16px;
  }
`
export const OutletSection = styled.div`
  padding: 20px;
  @media (max-width: 450px) {
    padding: 16px 0;
  }
`
export const OutletCardSection = styled.div`
  display: flex;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  width: max-content;
  img {
    width: 100%;
  }
  @media (max-width: 450px) {
    height: 200px;
  }
`
export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px !important;
`
export const StoreName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
`
export const StoreLocation = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #979797;
`
export const StoreTiming = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  span {
    font-weight: 600;
    color: #115469;
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
  @media (max-width: 1150px) {
    justify-content: center;
    align-items: center;
  }
`
export const OutletContactInfoSection = styled.div`
  background: #e7eef0;
  border-radius: 16px;
  position: relative;
  padding: 24px;
  max-width: 400px;
  min-width: 400px;
  max-height: 270px;
  width: 100%;
  @media (max-width: 450px) {
    max-width: 300px;
    min-width: 300px;
  }
`
export const ContactInfoTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`
export const ContactInfoDescription = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`
export const ContactInfoButtonSection = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 20px;
  button {
    max-width: 170px;
    width: 100%;
    text-transform: none;
    border-radius: 8px;
  }
`
