import React from 'react'
import { Avatar } from '@mui/material'
import {
  ReviewComments,
  ReviewContainer,
  ReviewContent,
  ReviewerDetailWrapper,
  ReviewerName,
  ReviewerLocation,
} from 'styles/hpLayout/testimonials/review'

const Review = ({ reviewerName, reviewerImage, comments, location }) => {
  return (
    <ReviewContainer>
      <Avatar alt={reviewerName} src={reviewerImage} sx={{ width: 80, height: 80 }} />
      <ReviewContent>
        <ReviewComments>{comments}</ReviewComments>
        <ReviewerDetailWrapper>
          <ReviewerName>-{reviewerName}</ReviewerName>
          <ReviewerLocation>{location}</ReviewerLocation>
        </ReviewerDetailWrapper>
      </ReviewContent>
    </ReviewContainer>
  )
}

export default Review
