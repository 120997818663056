import React from 'react'
import FooterApi from 'utils/FooterApi'
import BannerSection from 'views/BannerSection'
import BannerImage from 'assets/images/HpImages/banner-image.png'
import { MainWrapper, AboutUsWrapper, ContentWrapper } from 'styles/FooterLinks/AboutUsPage'

const AboutUsPage = () => {
  const { data } = FooterApi('aboutus')

  return (
    <MainWrapper>
      <BannerSection title="About Us" bannerImage={BannerImage}/>
      <AboutUsWrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: data?.aboutus }} />
        </ContentWrapper>
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default AboutUsPage
