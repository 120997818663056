import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const OrderDeatilWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
`
export const OrderWrapper = styled.div`
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 8px;
  padding: 12px 24px;
`
export const OrderIdWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const OrderId = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const OrderDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const DetailWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.TERTIARYCOLORDARK};
`
export const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.SECONDARYGREYCOLOR};
`
export const OrderHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.PRIMARYCOLOR};
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const OrderImageWrapper = styled.div`
  img {
    max-width: 110px;
    width: 100%;
    max-height: 110px;
  }
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const StoreTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.TEXTCOLORLIGHT};
`
export const QunatityWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const QuantityTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.TERTIARYCOLORDARK};
`
export const ProductQuantity = styled.div`
  display: flex;
  gap: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.SECONDARYGREYCOLOR};
`
export const ButtonWrapper = styled.div`
  padding: 20px 0;
  button {
    color: ${theme.SECONDARYTEXTCOLOR} !important;
    font-weight: 500;
    text-transform: none;
  }
`
