import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 50px 0 0 0;
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: ${theme.HPSECONDARYCOLOR};
    font-size: 10px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  @media (max-width: 767px) {
    gap: 40px;
    margin: 160px 9px 0;
  }
  @media (max-width: 500px) {
    gap: 40px;
    margin: 140px 9px 0;
  }
  @media (max-width: 400px) {
    gap: 40px;
    /* margin: 100px 9px 0; */
  }
`
export const MainHeading = styled.p`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  text-align: center;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 31.81px;
  }
`
export const ReviewsContainer = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const ReviewsSection = styled.div`
  margin-left: 30px;
  ${ReviewsContainer} {
    opacity: 0.5;
    transform: scale(0.79);
  }
  .slick-slide.slick-active.slick-current {
    ${ReviewsContainer} {
      transform: scale(1);
      opacity: 1;
    }
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;

  svg > path {
    stroke: ${theme.PRIMARYCOLOR};
    width: 20px;
    height: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
