import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  max-width: 300px;
  min-width: 300px;
  @media (max-width: 767px) {
    max-width: unset;
    min-width: unset;
  }
`
export const Wrapper = styled.div`
  display: flex;
`
export const RadioContent = styled.div`
  padding: 0 12px;
`
export const RadioTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
`
export const ActionSection = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
`
export const RatingSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 5px;
`
export const RatingOptionSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
export const ButtonSection = styled.div`
  border: none;
  background: transparent;
`
export const ActionButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #e8e8e8;
`
