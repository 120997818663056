import React from 'react'
import FooterApi from 'utils/FooterApi'
import BannerSection from 'views/BannerSection'
import BannerImage from 'assets/images/HpImages/banner-image.png'
import { MainWrapper, AboutUsWrapper, ContentWrapper } from 'styles/FooterLinks/AboutUsPage'

const ReturnAndRefund = () => {
  const { data } = FooterApi('returnpolicy')
  return (
    <MainWrapper>
      <BannerSection title="Returns & Refunds" bannerImage={BannerImage}/>
      <AboutUsWrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: data?.returnpolicy }} />
        </ContentWrapper>
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default ReturnAndRefund
