import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const IconWrapper = styled.div`
  img {
    width: 150px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const MainHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  text-align: center;
`
