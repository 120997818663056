import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const OutOfStockWrapper = styled.div`
  position: absolute;
  z-index: 1;
`
export const OutOfStockBackgroundWrapper = styled.div`
  position: relative;
  img {
    transform: translateX(-15px);
  }
  @media (max-width: 450px) {
    img {
      /* transform: translateX(-9px); */
    }
  }
`
export const OutOfStockTitle = styled.p`
  position: absolute;
  top: 11px;
  left: -8px;
  width: max-content;
  font-weight: 700;
  font-size: 9px;
  line-height: 5.75px;
  color: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 450px) {
    top: 12px;
  }
`
