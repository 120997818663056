import React from 'react'
import { InputContainer } from '../../../styles/auth/Login'
import styles from './input.module.scss'
import EmailIcon from '../../../assets/svg/EmailIcon'

export default function Input(props) {
  const { has_error = '', required = false, icon } = props
  return (
    <div className="py-2">
      <label htmlFor={props.name} className={`${styles.form_label} ${required ? styles.required : ''}`}>
        {props.label_name}
      </label>
      <InputContainer>
        {icon === 'email' && <EmailIcon className={styles.icon} />}
        <input {...props} className={`${has_error ? styles.error : styles.formControl}`} />
      </InputContainer>
    </div>
  )
}
