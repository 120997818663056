import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 150px;
`
export const Wrapper = styled.div`
  text-align: center;
`
export const ImageSection = styled.div`
  width: 400px;
  text-align: center;
`
export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1d1d1d;
`
export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5f6c72;
`
export const ButtonWrapper = styled.div`
  button {
    text-transform: none;
  }
`
