import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const MainWrapper = styled.div``
export const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 70px 100px 0;
  @media (max-width: 767px) {
    margin: 30px 16px;
    gap: 24px;
  }
`
export const AboutUsHeading = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
export const AboutUsContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  white-space: pre-line;
`
export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ContentSection = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  padding-top: 15px;
`
export const ListWrapper = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  padding-top: 15px;
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 150px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
  gap: 10px;
`
/******** Purchase Protection Page *******/

export const PurchaseProtectionWrapper = styled.div`
  margin: 30px 100px;
  @media (max-width: 767px) {
    margin: 16px;
  }
`
export const PurchaseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 20px 0;
  @media (max-width: 767px) {
    margin: 16px;
    gap: 24px;
  }
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${theme.PRIMARYCOLOR};
  cursor: pointer;
`
