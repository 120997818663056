import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <path
      fill="#CBCBCB"
      fillRule="evenodd"
      d="M45.003.408c24.72 0 44.791 20.07 44.791 44.791 0 24.721-20.07 44.792-44.791 44.792C20.282 89.991.21 69.921.21 45.199.21 20.48 20.28.408 45.003.408Zm0 6.25c-21.271 0-38.542 17.27-38.542 38.541 0 21.271 17.27 38.542 38.542 38.542 21.27 0 38.541-17.27 38.541-38.542 0-21.27-17.27-38.541-38.541-38.541Z"
      clipRule="evenodd"
    />
    <path
      fill="#CBCBCB"
      fillRule="evenodd"
      d="M76.673 72.454a3.124 3.124 0 0 1-4.416 4.416L13.332 17.945a3.124 3.124 0 0 1 4.416-4.417l58.925 58.925Z"
      clipRule="evenodd"
    />
    <path
      fill="#CBCBCB"
      fillRule="evenodd"
      d="m30.703 30.9 12.462-9.063a3.13 3.13 0 0 1 3.675 0l22.917 16.667a3.129 3.129 0 0 1-3.675 5.058l-1.287-.937v20.283h1.041a3.125 3.125 0 0 1 2.209 5.333L30.703 30.9Zm27.946 38.258h-34.48a3.125 3.125 0 0 1 0-6.25h1.042V42.625l-1.287.937a3.13 3.13 0 0 1-3.675-5.058l4.487-3.263L58.65 69.158Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
