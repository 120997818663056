import { makeStyles } from '@mui/styles'
import palette from '../../../../utils/Theme/palette'
import styled from 'styled-components'

const useStyles = makeStyles({
  expandIcon: {
    height: '30px !important',
    width: '30px !important',
    '& g path': {
      fill: `${palette.common.black} !important`,
    },
  },
  divider: {
    height: '1px',
    backgroundColor: '#E0E0E0 !important',
  },

  itemNameTypo: {
    marginTop: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '22px !important',
  },
  itemPriceTypo: {
    marginTop: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
  },
  itemDescriptionTypo: {
    color: '#686868 !important',
    marginTop: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
  },
  itemCard: {
    display: 'flex',
    borderRadius: '16px !important',
    background: '#F5F5F5 !important',
    height: '152px !important',
    width: '100% !important',
    position: 'relative !important',
  },
  cardAction: {
    marginTop: '-27px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  addToCartIcon: {
    borderRadius: '60px !important',
    background: '#fff',
  },
  itemImage: {
    margin: 'auto !important',
    height: '100% !important',
    width: '100% !important',
  },
  vegNonvegIcon: {
    position: 'absolute !important',
    height: '20px !important',
    width: '20px !important',
    top: '8px',
    right: '8px',
  },
  progressBarContainer: {
    textAlign: 'center !important',
    marginTop: '16px !important',
  },
  menuButtonContainer: {
    textAlign: 'center',
    marginTop: '16px !important',
  },
  menuFloatingButton: {
    // position: "absolute !important",
    // bottom: 0
  },
  menuIcon: {
    marginRight: '10px !important',
    height: '29px !important',
    width: '29px !important',
  },
  dialogMenuName: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    cursor: 'pointer',
    marginBottom: '24px !important',
    //   width: '350px !important'
  },
  isActiveMenu: {
    color: `${palette.primary.main} !important`,
    fontSize: '20px !important',
    fontWeight: '500 !important',
  },
  dialogMenuItemName: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    cursor: 'pointer',
    marginBottom: '24px !important',
    marginLeft: '15px !important',
    width: '335px !important',
  },
  plusIcon: {
    background: `${palette.primary.light}`,
    marginLeft: '10px !important',
    borderRadius: '50px !important',
  },
  itemsCount: {
    float: 'right',
    fontWeight: '600 !important',
  },
  qty: {
    borderRadius: '12px',
    border: '1px solid #196AAB',
    background: '#EAF6FF',
    marginRight: 14,
  },
  qtyIcon: {
    color: '#196AAB',
    cursor: 'pointer',
  },
  loader: {
    textAlign: 'left !important',
    display: 'flex',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: '16px !important',
  },
})

export default useStyles

export const MenuSection = styled.div``

export const AccordionSection = styled.div`
  .accordion {
    box-shadow: none;
  }
  .accordion-header {
    background: #f6f6f6;
    min-height: 40px;
    margin: 9px 0;
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 9px 0 !important;
    }
    svg {
      height: 30px !important;
      width: 30px !important;
      path {
        fill: #686868;
      }
    }
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`
export const ItemDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #1d1d1d;
`
export const MenuItemPrice = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #115469;
`
export const MenuItemDescription = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #686868;
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 10px;
  button {
    max-width: 176px;
    width: 100%;
    text-transform: none;
    border-radius: 8px;
  }
`
export const MenuItemImageSection = styled.div`
  display: flex;
  border-radius: 16px;
  background: #f5f5f5;
  height: 106px;
  width: 100%;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  .item-image {
    cursor: pointer;
    margin: auto !important;
    width: 100% !important;
    height: 100%;
    object-fit: contain;
  }
`
