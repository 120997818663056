import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" {...props}>
    <rect width={36} height={36} fill="#fff" fillOpacity={0.1} rx={8} />
    <rect width={35} height={35} x={0.5} y={0.5} stroke="#fff" strokeOpacity={0.2} rx={7.5} />
    <path
      fill="#fff"
      d="M17.963 14.075a3.878 3.878 0 0 0-3.884 3.884 3.878 3.878 0 0 0 3.884 3.884 3.878 3.878 0 0 0 3.884-3.884 3.878 3.878 0 0 0-3.884-3.884Zm0 6.41a2.53 2.53 0 0 1-2.525-2.526 2.528 2.528 0 0 1 2.525-2.525 2.528 2.528 0 0 1 2.525 2.525 2.53 2.53 0 0 1-2.525 2.526Zm4.949-6.569a.904.904 0 0 1-.906.906.906.906 0 1 1 .906-.906Zm2.572.92c-.057-1.214-.335-2.289-1.224-3.174-.885-.886-1.96-1.163-3.174-1.224-1.25-.071-5-.071-6.25 0-1.21.057-2.285.335-3.174 1.22-.89.886-1.163 1.961-1.224 3.175-.071 1.25-.071 5 0 6.25.057 1.214.335 2.289 1.224 3.174.889.886 1.96 1.163 3.174 1.224 1.25.07 5 .07 6.25 0 1.214-.058 2.289-.335 3.175-1.224.885-.886 1.162-1.96 1.223-3.174.071-1.25.071-4.996 0-6.247Zm-1.616 7.589a2.557 2.557 0 0 1-1.44 1.44c-.997.396-3.363.304-4.465.304-1.102 0-3.472.088-4.466-.304a2.557 2.557 0 0 1-1.44-1.44c-.395-.997-.304-3.364-.304-4.466 0-1.102-.088-3.471.304-4.465a2.557 2.557 0 0 1 1.44-1.44c.998-.396 3.364-.304 4.466-.304 1.102 0 3.471-.088 4.465.304a2.557 2.557 0 0 1 1.44 1.44c.396.997.305 3.363.305 4.465 0 1.102.09 3.472-.305 4.466Z"
    />
  </svg>
)
export default SvgComponent
