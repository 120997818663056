import styled from 'styled-components'
import empoweringWomenFarmersBackground from 'assets/images/HpImages/empoweringWomenFarmers-background.png'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  background-image: url(${empoweringWomenFarmersBackground});
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  @media (max-width: 767px) {
    height: 34vh;
  }
  @media (max-width: 500px) {
    height: 34vh;
    margin-top: 30px;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  width: 100%;
  padding: 40px 0;
  @media (max-width: 767px) {
    gap: 32px;
    justify-content: unset;
    align-items: unset;
  }
`
export const Heading = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  @media (max-width: 767px) {
    text-align: center;
    font-size: 28px;
    line-height: 31.81px;
  }
`
export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 767px) {
    display: none;
  }
`
export const BoxSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350.33px;
  min-width: 256.75px;
  width: 100%;
  height: 309px;
  box-shadow: 0px 4px 40px 0px #00000014;
  background: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  border-radius: 15px;
  cursor: pointer;
  @media (max-width: 767px) {
    max-width: 296px;
    min-width: 296px;
    height: 224px;
  }
  @media (max-width: 500px) {
    max-width: 360px;
    min-width: 360px;
    height: 264px;
    box-shadow: 0px 2.64px 26.41px 0px #00000014;
  }
  @media (max-width: 400px) {
    max-width: 320px;
    min-width: 320px;
    height: 264px;
  }
`
export const ImageWrapper = styled.div`
  img {
    width: 350.33px;
    height: 175px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    img {
      width: 296px;
      height: 115px;
    }
  }
  @media (max-width: 500px) {
    img {
      width: 360px;
      height: 115px;
    }
  }
  @media (max-width: 400px) {
    img {
      width: 320px;
      height: 115px;
    }
  }
`
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  @media (max-width: 767px) {
    padding: 11px;
  }
`
export const DateAndCategorySection = styled.div`
  display: flex;
  gap: 8px;
`
export const DateWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.HPSECONDARYLIGHTTEXTCOLOR};
`
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CategoryWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.HPPRIMARYCOLOR};
  text-transform: capitalize;
`
export const Description = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.HPTEXTCOLORLIGHT};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 15.84px;
    // height: 0;
    -webkit-line-clamp: 2;
  }
`
export const ReadMoreText = styled.div`
  font-weight: 600;
  font-size: 12x;
  line-height: 24px;
  color: ${theme.HPSECONDARYCOLOR};
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 9.24px;
    line-height: 15.84px;
  }
`
export const MobileContentGrid = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    /* .slick-track {
      display: flex;
      gap: 20px;
    }
    .slick-list {
      display: flex;
      width: 100%;
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      width: 100% !important;
    } */
    /* .slick-slider {
      width: 100vw;
    } */
    .slick-slider {
      width: 100%;
    }
    .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    }
    .slick-list {
      margin-left: 20px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: ${theme.HPSECONDARYCOLOR};
      font-size: 10px;
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
  }
`
