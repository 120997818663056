/* eslint-disable no-unused-vars */
import { useState } from 'react'
import AccordianMinusIcon from 'assets/svg/AccordianMinusIcon'
import AccordianPlusIcon from 'assets/svg/AccordianPlusIcon'
import {
  FaqAccordianContainer,
  FaqSectionContainer,
  FaqSectionFrame,
  FaqAccordian,
  FaqAccordianContent,
  FaqAccordianHeading,
  FaqAccordianTitle,
  FaqLists,
} from 'styles/FooterLinks/FAQs'

const FaqSection = ({ faqData }) => {
  const [id, setId] = useState(null)

  const handleClick = (index) => {
    if (id === index) setId(null)
    else setId(index)
  }

  return (
    <FaqSectionContainer>
      <FaqSectionFrame>
        <div>
          <FaqAccordianContainer>
            {faqData?.map((faq, i) => (
              <Accordian faqData={faq} key={i} i={i} id={id} handleClick={handleClick} />
            ))}
          </FaqAccordianContainer>
        </div>
      </FaqSectionFrame>
    </FaqSectionContainer>
  )
}

const Accordian = ({ faqData, i, id, handleClick }) => {
  return (
    <FaqAccordian isOpen={i === id} key={i}>
      <FaqAccordianHeading isOpen={i === id} onClick={() => handleClick(i)}>
        <FaqAccordianTitle>{faqData?.heading}</FaqAccordianTitle>
        {i == id ? <AccordianMinusIcon /> : <AccordianPlusIcon />}
      </FaqAccordianHeading>
      {i === id && (
        <FaqAccordianContent>
          <div>{faqData?.content}</div>
          {faqData?.content?.list && (
            <ul>
              {faqData?.content?.list?.map((item, index) => (
                <FaqLists key={index}>
                  <span className="title">{item?.title}</span>: <span>{item.quote}</span>
                </FaqLists>
              ))}
            </ul>
          )}
        </FaqAccordianContent>
      )}
    </FaqAccordian>
  )
}

export default FaqSection
