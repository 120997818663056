import { useState, useEffect, useMemo } from 'react'
import Pagination from '@mui/material/Pagination'
import useGet from 'hooks/useGet'
import Spinner from 'components/Loader'
import ComplaintsCard from 'views/Complaints/ComplaintsCard'
import { Container, PaginationWrapper } from 'styles/ComplaintsCard'

const ActiveComplaints = ({ orderId }) => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalOrder: 0,
    postPerPage: 10,
    status: 'activeIssues',
  })
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const apiUrl = useMemo(() => {
    let url = `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.status}`
    if (orderId) {
      setPagination((prev) => ({ ...prev, currentPage: 1 }))
      url += `&search=${orderId}`
    }

    return url
  }, [pagination.currentPage, pagination.postPerPage, pagination.status, orderId])

  const { data: getIssues, refetch } = useGet('get-issues', apiUrl)

  useEffect(() => {
    setLoading(true)
    refetch().then(() => setLoading(false))
  }, [apiUrl, refetch])

  useEffect(() => {
    if (getIssues) {
      setPagination((prev) => ({
        ...prev,
        totalOrder: getIssues.totalOrder || 0,
      }))
      setTotalOrdersCount(getIssues.totalOrder)
    }
  }, [getIssues])

  const issueList = getIssues?.issues

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ComplaintsCard issueDetails={issueList} />
          <PaginationWrapper>
            <Pagination
              count={Math.ceil(totalOrdersCount / pagination.postPerPage)}
              shape="rounded"
              color="primary"
              page={pagination.currentPage}
              onChange={(event, currentPage) => {
                event
                setPagination((prevState) => ({ ...prevState, currentPage }))
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            />
          </PaginationWrapper>
        </>
      )}
    </Container>
  )
}

export default ActiveComplaints
