import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Navbar from 'components/hpLayout/navbar/navbar'
import Footer from 'components/hpLayout/footer/footer'
import { Wrapper } from 'styles/hpLayout'
import { BoxWrapper } from 'styles/navbar'

const HpLayout = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [location])

  return (
    <Wrapper>
      <Navbar />
      <BoxWrapper>
        <Box component="main">{children}</Box>
      </BoxWrapper>
      <Footer />
    </Wrapper>
  )
}

export default HpLayout
