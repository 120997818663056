import styled from 'styled-components'

export const MainContainer = styled.div`
  position: 'absolute';
  top: '15px';
  left: '15px';
  width: '100%';
  max-width: '400px';
  word-break: 'normal';
`
export const Wrapper = styled.div`
  height: '90vh';
  overflow-y: 'auto';
  overflow-x: 'hidden';
`
