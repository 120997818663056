import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={73} height={73} fill="none" {...props}>
    <path
      fill="#734D00"
      d="M43.172 9.83a19.689 19.689 0 0 1 4.819-7.458 1.125 1.125 0 0 0 .28-1.245 1.146 1.146 0 0 0-1.068-.705c-.627.014-15.45.441-22.513 10.45-3.663 5.192-3.614 8.771-3.081 11.588a7.05 7.05 0 0 0 6.982 5.744h.093a13.04 13.04 0 0 0 9.622-4.641c2.88-3.08 3.627-7.323 4.228-10.734a27.6 27.6 0 0 1 .638-2.999Zm-2.85 2.609c-.55 3.117-1.233 6.996-3.656 9.585a10.877 10.877 0 0 1-7.98 3.93h-.08a4.822 4.822 0 0 1-4.789-3.912c-.45-2.354-.471-5.367 2.709-9.873 4.657-6.6 13.439-8.624 17.928-9.236a19.596 19.596 0 0 0-3.434 6.239 28.803 28.803 0 0 0-.696 3.267h-.002Z"
    />
    <path
      fill="#734D00"
      d="M21.436 11.996c-8.157-5.25-19.2-2.05-19.674-1.91a1.126 1.126 0 0 0-.15 2.1 16.015 16.015 0 0 1 5.633 4.618c.457.707.874 1.44 1.25 2.194 1.282 2.433 3.037 5.768 6.15 7.5a12.327 12.327 0 0 0 6 1.703c.175 0 .35 0 .524-.014a1.125 1.125 0 0 0 1.064-1.182 1.097 1.097 0 0 0-1.182-1.065 9.836 9.836 0 0 1-5.315-1.408c-2.526-1.409-4.038-4.28-5.25-6.585a24.226 24.226 0 0 0-1.392-2.433 16.178 16.178 0 0 0-3.97-3.854c3.6-.583 10.084-1 15.099 2.226a1.125 1.125 0 1 0 1.218-1.893l-.005.003Zm16.18-5.653a41.046 41.046 0 0 0-8.053 8.203 42.858 42.858 0 0 0-4.13 6.79 1.126 1.126 0 0 0 .562 1.49 1.125 1.125 0 0 0 1.486-.566 40.581 40.581 0 0 1 3.9-6.395 39.45 39.45 0 0 1 7.56-7.709 1.125 1.125 0 0 0-1.327-1.816l.003.003Z"
    />
    <path
      fill="#734D00"
      d="M11.605 14.032a1.128 1.128 0 0 0 .61 1.47 27.254 27.254 0 0 1 6.03 3.67 1.125 1.125 0 1 0 1.429-1.74 29.124 29.124 0 0 0-6.6-4.014 1.13 1.13 0 0 0-1.469.614ZM25.73 30.205a1.128 1.128 0 0 0-1.062-.79h-.012a1.125 1.125 0 0 0-1.066.767 53.776 53.776 0 0 1-5.061 10.266c-5.68 8.976-8.19 13.12-8.19 17.294 0 8.644 5.886 14.68 14.317 14.68 8.43 0 14.318-6.036 14.318-14.68 0-4.064-2.148-8.067-8.2-17.309a46.121 46.121 0 0 1-5.044-10.228Zm-1.074 39.967c-7.105 0-12.067-5.112-12.067-12.43 0-3.548 2.4-7.5 7.842-16.09a58.013 58.013 0 0 0 4.2-7.985 50.674 50.674 0 0 0 4.261 8.005c5.523 8.435 7.832 12.533 7.832 16.076 0 7.312-4.964 12.424-12.068 12.424Z"
    />
    <path
      fill="#734D00"
      d="M28.905 66.533a1.127 1.127 0 0 0 1.553.354 10.293 10.293 0 0 0 4.537-9.15 1.125 1.125 0 1 0-2.25 0 8.125 8.125 0 0 1-3.486 7.24 1.126 1.126 0 0 0-.354 1.556Zm3.167-11.587a1.126 1.126 0 0 0 2.086-.845 23.164 23.164 0 0 0-.418-.961 1.125 1.125 0 1 0-2.043.945c.14.3.264.585.375.86Z"
    />
  </svg>
)
export default SvgComponent
