import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import { deleteWithAuthentication } from '../../api/axios'
import { ToastContext } from '../../context/toastContext'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { RevokeModalWrapper, RevokeContainer, ContentWrapper, ButtonContainer } from '../../styles/AddressDeleteModal'

const DeleteAddressModal = ({ onClose, id, fetchDeliveryAddress }) => {
  const dispatch = useContext(ToastContext)
  const deleteAddressItem = async (itemId) => {
    try {
      const url = `/clientApis/v1/delete_delivery_address/${itemId}`
      await deleteWithAuthentication(url)
      onClose()
      fetchDeliveryAddress()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Address has bee deleted successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  return (
    <RevokeModalWrapper>
      <RevokeContainer>
        <ContentWrapper>Are you sure you want to delete the address?</ContentWrapper>
        <ButtonContainer>
          <Button color="primary" variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteAddressItem(id)
            }}
          >
            Yes, Delete
          </Button>
        </ButtonContainer>
      </RevokeContainer>
    </RevokeModalWrapper>
  )
}

export default DeleteAddressModal
