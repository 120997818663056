import { styled } from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  width: ${({ isOtpPage }) => (isOtpPage ? '350px' : '400px')};
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 450px) {
    width: ${({ isOtpPage }) => (isOtpPage ? '315px' : '100%')};
  }
`
export const ImageContainer = styled.div`
  width: 50%;
  img {
    width: 100%;
    max-height: 100vh;
  }
  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: arrow;
  img {
    max-width: 80px;
    height: 100%;
  }
`
export const CrossIconWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`
export const OtpLogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 115px;
  cursor: pointer;
  img {
    max-width: 80px;
    height: 100%;
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const FormContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`
export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  a {
    color: transparent;
  }
  input {
    padding: 11px 2px;
    color: ${theme.INPUTCOLOR};
    font-weight: 400;
    font-size: 14px;
  }

  ::placeholder {
    color: ${theme.PRIMARYTEXTCOLOR} !important;
  }
  /* input {
    background: ${theme.TERTIARYBACKGROUNDCOLOR};
    color: ${theme.INPUTCOLOR};
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 8px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;

    ::placeholder {
      color: ${theme.INPUTCOLOR};
    }
  } */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    max-width: 350px;
  }
`
export const Label = styled.div`
  color: ${theme.LABELCOLOR};
  font-weight: 500;
  font-size: 14px;
  margin: 20px 0 5px 0;
`
export const ForgotText = styled.div`
  color: ${theme.PRIMARYTEXTCOLOR};
  font-weight: 500;
  font-size: 14px;
  margin: 20px 0;
  text-align: right;
  cursor: pointer;
  width: max-content;
`

export const Title = styled.div`
  color: ${theme.PRIMARYTEXTCOLORDARK};
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
`
export const OrText = styled.div`
  margin: 15px auto;
  text-align: center;
  color: ${theme.INPUTCOLOR};
  font-size: 14px;
  font-weight: 400;
`
// export const InputContainer = styled.div`
//   display: flex;
//   position: relative;
//   background: ${theme.TERTIARYBACKGROUNDCOLOR};
//   color: ${theme.INPUTCOLOR};
//   border: none;
//   outline: none;
//   border-radius: 8px;
//   width: 100%;
//   font-weight: 400;
//   font-size: 14px;
//   img {
//     position: absolute;
//     right: 10px;
//     z-index: 2;
//     top: 10px;
//     cursor: pointer;
//   }
//   input {
//     border: none !important;
//     color: ${theme.INPUTCOLOR} !important;
//   }
// `
export const InputContainer = styled.div`
  display: flex;
  position: relative;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  color: ${theme.INPUTCOLOR};
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  padding: 0 10px;
  input {
    border: none !important;
    color: ${theme.PRIMARYTEXTCOLOR} !important;

    /* padding-right: 30px; */
  }
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  svg {
    path {
      margin-right: 10px;
    }
  }
`

export const GoogleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${theme.PRIMARYTEXTCOLOR};
  > img {
    height: 20px;
  }
`
export const ButtonText = styled.div`
  width: max-content;
`
export const NavigationLinks = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px auto;
  justify-content: center;
  gap: 10px;
`
export const SignUpText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.PRIMARYTEXTCOLOR};
  cursor: pointer;
`
export const AccountText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  .login_button {
    padding: 9px;
    background: ${theme.PRIMARYCOLOR} !important;
    border: none !important;
    text-align: center;
    text-transform: capitalize;
    font-family: 'montserrat' !important;
    color: ${theme.SECONDARYTEXTCOLOR};
    width: 200px;
  }
  .google_button {
    background: ${theme.TERTIARYBACKGROUNDCOLOR} !important;
    border: none !important;
    text-align: center;
    text-transform: capitalize;
    font-family: 'montserrat' !important;
    color: ${theme.SECONDARYTEXTCOLOR};
    width: 200px;
    padding: 11px;
    border-radius: 6px;
  }
`
export const PreviousWrapper = styled.div`
  button {
    padding: 12px;
    background: ${theme.SECONDARYTEXTCOLOR};
    border-radius: 50%;
    text-align: center;
    text-transform: capitalize;
    font-family: 'montserrat' !important;
    border: none;
    svg {
      path {
        stroke: ${theme.PRIMARYCOLOR};
      }
    }
  }
`
export const InputWrapper = styled.div`
  min-height: 120px;
  span {
    font-size: 12px;
    text-align: left;
    margin: 0;
    color: ${theme.ERROR};
  }
  @media (max-width: 450px) {
    min-height: 94px;
  }
`
