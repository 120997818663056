import User1 from 'assets/images/HpImages/reviewer-image1.png'
import User2 from 'assets/images/HpImages/reviewer-image2.png'
import User3 from 'assets/images/HpImages/reviewer-image3.png'
import User4 from 'assets/images/HpImages/reviewer-image2.png'
export const data = [
  {
    reviewerImage: User1,
    comments:
      'The Mango Pickle is a perfect blend of tangy and spicy, and the Apple Jam is like a burst of fresh fruit in every bite! Absolutely love these HimIra products!',
    reviewerName: 'Rajat Sharma',
    reviwerLocation: 'Delhi',
  },
  {
    reviewerImage: User2,
    comments:
      "HimIra Ghee has completely transformed my cooking! The rich, authentic flavor takes me back to my roots, and I love that it’s made by empowered women in Himachal. It's not just ghee, it’s a part of their story.",
    reviewerName: 'Priyanka Sharma',
    reviwerLocation: 'Pune',
  },
  {
    reviewerImage: User3,
    comments:
      'HimIra Apricot Oil is incredible for my skin! It’s light, non-greasy, and leaves my skin so smooth. Plus, supporting local women makes it even better',
    reviewerName: 'Aastha Negi',
    reviwerLocation: 'Kinnaur',
  },
  {
    reviewerImage: User1,
    comments:
      'The Mango Pickle is a perfect blend of tangy and spicy, and the Apple Jam is like a burst of fresh fruit in every bite! Absolutely love these HimIra products!',
    reviewerName: 'Rajat Sharma',
    reviwerLocation: 'Delhi',
  },
  {
    reviewerImage: User4,
    comments:
      "HimIra Ghee has completely transformed my cooking! The rich, authentic flavor takes me back to my roots, and I love that it’s made by empowered women in Himachal. It's not just ghee, it’s a part of their story.",
    reviewerName: 'Priyanka Sharma',
    reviwerLocation: 'Pune',
  },
  {
    reviewerImage: User3,
    comments:
      'HimIra Apricot Oil is incredible for my skin! It’s light, non-greasy, and leaves my skin so smooth. Plus, supporting local women makes it even better',
    reviewerName: 'Aastha Negi',
    reviwerLocation: 'Kinnaur',
  },
]
