import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ProfileMainWrapper = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    margin: 0 16px;
    width: unset;
  }
`
export const FormTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.TERTIARYCOLORDARK};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const FormWrapper = styled.form`
  margin-bottom: 20px;
`
export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const UserImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  label {
    width: 120px;
    height: 120px;
    border: 3.72px dashed ${theme.GREYCOLORDARK};
    background-color: ${theme.BACKGROUNDCOLORLIGHT};
    box-shadow: none;
    border-radius: 50%;
    overflow: hidden;
    span {
      margin: 0;
      /* width: 120px; */
      height: 120px;
    }
  }
  &:hover {
    label {
      background-color: ${theme.BACKGROUNDCOLORLIGHT};
      box-shadow: none;
    }
  }
  @media (max-width: 767px) {
    label {
      width: 72px;
      height: 72px;
    }
  }
`
export const SupportedImageFormats = styled.div`
  color: ${theme.GREYCOLOR};
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
`
export const UserInformationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  @media (max-width: 767px) {
    grid-template-columns: unset;
    gap: 0;
  }
`
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .phone-input {
    input {
      width: 100% !important;
    }
  }
  input {
    padding: 8px;
    font-size: 12px;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  }
  .locality-wrapper {
    height: 36px !important;
    font-size: 12px !important;
    &:disabled {
      cursor: not-allowed;
      background: red !important;
    }
  }
`
export const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 56px;
  .phone-input {
    & :disabled {
      cursor: not-allowed;
    }
  }
  input {
    &:disabled {
      cursor: not-allowed;
    }
  }
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`
export const AdresssInformationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 767px) {
    grid-template-columns: unset;
    gap: 0;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  button {
    color: #fff;
    font-weight: 500;
    text-transform: none;
  }
`
