import React from 'react'
import { useHistory } from 'react-router-dom'
import FooterApi from 'utils/FooterApi'
import PreviousIcon from 'assets/svg/LeftArrow'
import { PurchaseProtectionWrapper, PurchaseContentWrapper, IconButton } from 'styles/FooterLinks/AboutUsPage'

const BharthamPurchaseProtection = () => {
  const history = useHistory()
  const { data } = FooterApi('protectionPolicy')
  const handleBackClick = () => {
    history.goBack()
  }

  return (
    <PurchaseProtectionWrapper>
      <IconButton onClick={handleBackClick}>
        <PreviousIcon />
      </IconButton>
      <PurchaseContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: data?.protectionPolicy }} />
      </PurchaseContentWrapper>
    </PurchaseProtectionWrapper>
  )
}

export default BharthamPurchaseProtection
