import React from 'react'
import { ONDC_COLORS } from '../colors'

export default function Like(props) {
  const { width = '30', height = '30', like = false } = props
  return (
    <svg transform="rotate(180deg)" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 0C13.1484 0 12.6127 0.352732 12.3657 0.888071L8.37734 9H3.98322C2.32771 9 1 10.3511 1 12V19C1 20.6489 2.32771 22 3.98322 22H18.6434C20.1225 22 21.3697 20.9128 21.5921 19.4549L22.9651 10.4549C23.2408 8.64775 21.854 7 20.0164 7H16.8825L16.8826 3.62846C16.8826 3.28115 16.8826 2.88736 16.8438 2.51718C16.8037 2.13526 16.7159 1.69889 16.4904 1.29245C15.9723 0.358596 14.9922 0 13.75 0ZM10.1721 9.88245L14.0429 2.00983C14.6349 2.05286 14.7218 2.22401 14.7396 2.25902L14.7416 2.26277C14.7826 2.33678 14.8281 2.47249 14.8547 2.72584C14.8815 2.98149 14.8825 3.27548 14.8825 3.65493V8C14.8825 8.55228 15.3303 9 15.8825 9H20.0164C20.6097 9 21.0819 9.53745 20.9879 10.1533L19.615 19.1533C19.5397 19.6467 19.122 20 18.6434 20H8V11H8.37734C9.1397 11 9.83576 10.5666 10.1721 9.88245ZM6 11H3.98322C3.44813 11 3 11.4398 3 12V19C3 19.5602 3.44813 20 3.98322 20H6V11Z"
        fill={like ? ONDC_COLORS.SUCCESS : ONDC_COLORS.SECONDARYCOLOR}
      />
    </svg>
  )
}
