import React, { useState } from 'react'
import styles from './input.module.scss'
import EyeVisible from '../../../assets/images/eye_visible.png'
import EyeHidden from '../../../assets/images/eye_hidden.png'
import { InputContainer } from '../../../styles/auth/Login'
import PasswordIcon from '../../../assets/svg/PasswordIcon'

export default function Input(props) {
  const { has_error = '', required = false, icon } = props
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div>
      <label htmlFor={props.name} className={`${styles.form_label} ${required ? styles.required : ''}`}>
        {props.label_name}
      </label>
      <InputContainer>
        {icon === 'password' && <PasswordIcon className={styles.icon} />}
        <input
          type={showPassword ? 'text' : 'password'}
          {...props}
          className={`${has_error ? styles.error : styles.formControl}`}
        />
        {showPassword ? (
          <img src={EyeHidden} alt="logo" style={{ height: '20px' }} onClick={() => setShowPassword(!showPassword)} />
        ) : (
          <img src={EyeVisible} alt="logo" style={{ height: '20px' }} onClick={() => setShowPassword(!showPassword)} />
        )}
      </InputContainer>
    </div>
  )
}
