export const toast_types = {
  error: 'error',
  success: 'success',
  warning: 'warning',
}

export const toast_actions = {
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
}
