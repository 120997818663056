import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={73} height={73} fill="none" {...props}>
    <g fill="#734D00" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M36.422 38.883H36c-7.734-3.234-12.656-10.687-12.656-19.125C23.344 11.462 28.266 4.15 36 .915a.897.897 0 0 1 .844 0C44.578 4.149 49.64 11.46 49.64 19.758c0 8.438-5.063 15.891-12.797 18.985-.14.14-.282.14-.422.14Zm0-36c-6.75 2.954-11.11 9.563-11.11 16.875 0 7.313 4.36 13.922 11.11 17.016 6.89-3.094 11.25-9.563 11.25-17.016-.14-7.312-4.36-13.921-11.25-16.875Z" />
      <path d="M36.422 72.774c-.563 0-.984-.422-.984-1.125V13.711c0-.562.421-1.125.984-1.125.562 0 1.125.563 1.125 1.125V71.65c-.14.703-.563 1.125-1.125 1.125Z" />
      <path d="M36.422 21.868c-.14 0-.422 0-.703-.281-.422-.422-.422-.985 0-1.407l4.5-4.64c.281-.422.984-.422 1.406 0 .422.421.422.984 0 1.406l-4.36 4.64c-.28.282-.562.282-.843.282Z" />
      <path d="M36.422 21.868c-.281 0-.422 0-.703-.281l-4.5-4.641c-.422-.281-.282-.984.14-1.406.422-.422.985-.282 1.407 0l4.5 4.64c.421.422.28 1.125-.141 1.547-.14.14-.422.14-.703.14Zm0 6.187c-.14 0-.422-.14-.703-.28a1.103 1.103 0 0 1 0-1.548l4.5-4.64c.28-.422.984-.422 1.406 0 .422.421.422.984 0 1.406l-4.5 4.781c-.14.14-.422.281-.703.281Z" />
      <path d="M36.422 28.055c-.281 0-.422-.14-.703-.28l-4.5-4.642c-.422-.421-.282-1.125.14-1.546.422-.422.985-.282 1.407.14l4.5 4.64c.421.423.28.985-.141 1.407-.14.14-.422.281-.703.281Zm0 6.047c-.14 0-.422 0-.703-.281-.422-.422-.422-.984 0-1.406l4.5-4.64c.28-.423.984-.423 1.406-.141a1.103 1.103 0 0 1 0 1.546l-4.36 4.64c-.28.282-.562.282-.843.282Z" />
      <path d="M36.422 34.102c-.281 0-.422 0-.703-.281l-4.5-4.64c-.422-.423-.282-.985.14-1.407.422-.422.985-.422 1.407 0l4.5 4.64c.422.422.28 1.126-.141 1.547-.14.141-.422.141-.703.141Zm-13.5 25.875c-2.531 0-5.063-.703-7.453-1.969-4.781-2.53-7.735-7.312-8.156-12.797 0-.28.14-.703.421-.984 4.782-2.531 10.547-2.531 15.188 0 4.781 2.672 7.734 7.313 8.156 12.797 0 .422-.281.703-.562.844-2.391 1.406-4.922 2.11-7.594 2.11Zm-13.5-14.062c.422 4.359 3.094 8.297 6.89 10.406 3.938 2.11 8.579 2.11 12.516.281-.422-4.36-2.953-8.297-7.031-10.406-3.797-2.25-8.438-2.39-12.375-.281Z" />
      <path d="M36.422 60.82c-.14 0-.422-.14-.703-.28-2.672-2.391-5.485-2.391-5.625-2.391-.563.14-1.125-.422-1.125-.844 0-.562.422-1.125.984-1.125.14 0 3.797-.14 7.172 2.953.422.282.563.985.14 1.407-.28.14-.562.28-.843.28Zm12.656-10.405c-2.672 0-5.203-.703-7.594-2.11-.422-.14-.562-.562-.562-.984.281-5.344 3.375-10.125 8.156-12.656 4.781-2.672 10.406-2.672 15.188 0 .28.14.421.562.421.984-.28 5.344-3.374 10.125-8.156 12.797a15.127 15.127 0 0 1-7.453 1.969Zm-6.047-3.516c4.078 1.969 8.719 1.828 12.657-.281 3.937-2.11 6.468-6.047 6.89-10.407-3.937-1.968-8.578-1.828-12.516.282-3.937 2.11-6.468 6.047-7.03 10.406Z" />
      <path d="M36.422 50.415c-.281 0-.563-.282-.703-.422-.422-.563-.282-1.125.14-1.406 3.094-2.25 6.047-2.11 6.188-2.11.562.14.984.563.984 1.125 0 .563-.562.985-1.125.985 0 0-2.39-.141-4.781 1.546-.281.141-.422.282-.703.282Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .774h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
