import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  Container,
  ContentSection,
  DateAndCategorySection,
  LatestBlogCardSection,
  LatestBlogImageSection,
  LatestBlogDetailSection,
  LatestBlogDateWrapper,
  LatestBlogCategoryWrapper,
  LatestBlogBlogTitle,
  LatestBlogReadMoreText,
  AllBlogsSection,
} from 'styles/hpLayout/blogsSection'

const AllBlogs = ({ data }) => {
  const history = useHistory()
  const handleClick = (id) => {
    history.push(`/blog/${id}`)
  }

  return (
    <Container>
      <AllBlogsSection>
        {data?.map((item, index) => (
          <LatestBlogCardSection key={index} onClick={() => handleClick(item?.id)}>
            <LatestBlogImageSection>
              <img src={item?.bannerImage} />
            </LatestBlogImageSection>
            <LatestBlogDetailSection>
              <ContentSection>
                <DateAndCategorySection>
                  <LatestBlogDateWrapper> {moment(item?.updatedAt).format('MMMM Do, YYYY')}</LatestBlogDateWrapper>
                  <LatestBlogCategoryWrapper>{item?.category}</LatestBlogCategoryWrapper>
                </DateAndCategorySection>
                <LatestBlogBlogTitle>{item?.title}</LatestBlogBlogTitle>
                <LatestBlogReadMoreText onClick={() => handleClick(item?.id)}>Read More</LatestBlogReadMoreText>
              </ContentSection>
            </LatestBlogDetailSection>
          </LatestBlogCardSection>
        ))}
      </AllBlogsSection>
    </Container>
  )
}

export default AllBlogs
