import { useState, useEffect, useContext } from 'react'
import ReactGA from 'react-ga4'
import { Skeleton } from '@mui/material'
import { getUserId, getOrCreateDeviceId } from 'helper'
import { getSelectCall } from 'api/axios'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
import Tab from 'components/Tab'
import BestSellingProducts from 'components/hpLayout/topSelling/bestSellingProducts'
import { Container, MainHeading, TabContainer } from 'styles/hpLayout/topSelling'

const TopSellingSection = () => {
  const dispatch = useContext(ToastContext)
  const [value, setValue] = useState('bestseller')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [deviceId, setDeviceId] = useState(null)

  const handleChange = (event, newValue) => {
    event
    setValue(newValue)
    getTopSellingProducts(newValue)
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Top Selling Products',
    })
  }, [])

  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  useEffect(() => {
    execute()
  }, [])
  const getTopSellingProducts = async (value) => {
    const userId = getUserId()
    setLoading(true) // Start loading
    const params = {
      tag: value,
    }
    try {
      const data = await getSelectCall(`/clientApis/v2/search/${userId}?deviceId=${deviceId}`, params)
      setData(data?.response?.data || [])
      setLoading(false) // Stop loading after data is fetched
      return data
    } catch (err) {
      setLoading(false) // Stop loading on error
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the top selling products. Please try again.',
        },
      })
    }
  }

  useEffect(() => {
    if (deviceId) {
      getTopSellingProducts(value)
    }
  }, [deviceId])

  const items = [
    {
      label: 'Best Seller',
      value: 'bestseller',
    },
    {
      label: 'Hot Selling',
      value: 'hotselling',
    },
    {
      label: 'Most Wishlisted',
      value: 'mostloved',
    },
  ]

  // Dynamic panel items data with loading state
  const panelItems = [
    {
      id: 'bestseller',
      data: loading ? (
        <Skeleton animation="wave" variant="rounded" width={4000} height={120} />
      ) : (
        <BestSellingProducts data={data} setData={setData} />
      ),
    },
    {
      id: 'hotselling',
      data: loading ? (
        <Skeleton animation="wave" variant="rounded" width={4000} height={120} />
      ) : (
        <BestSellingProducts data={data} setData={setData} />
      ),
    },
    {
      id: 'mostloved',
      data: loading ? (
        <Skeleton animation="wave" variant="rounded" width={4000} height={120} />
      ) : (
        <BestSellingProducts data={data} setData={setData} />
      ),
    },
  ]

  return (
    <Container>
      <MainHeading>Most Loved Products</MainHeading>
      <TabContainer style={{ width: '100%' }}>
        <Tab value={value} handleChange={handleChange} items={items} panelItems={panelItems} isCenteredPage />
      </TabContainer>
    </Container>
  )
}

export default TopSellingSection
