import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#979797"
      d="M12.938 6.75h-6.75V4.9a2.65 2.65 0 0 1 2.649-2.65h.326a2.65 2.65 0 0 1 2.65 2.65.562.562 0 0 0 1.124 0 3.78 3.78 0 0 0-3.774-3.775h-.326a3.78 3.78 0 0 0-3.774 3.774V6.75a1.687 1.687 0 0 0-1.688 1.688v5.23a3.212 3.212 0 0 0 3.206 3.207h4.838a3.212 3.212 0 0 0 3.206-3.206V8.437a1.687 1.687 0 0 0-1.688-1.687Zm.562 6.919a2.081 2.081 0 0 1-2.081 2.081H6.58A2.081 2.081 0 0 1 4.5 13.669V8.437a.562.562 0 0 1 .563-.562h7.875a.562.562 0 0 1 .562.563v5.23Zm-3.938-2.014V13.5a.562.562 0 1 1-1.124 0v-1.845a1.125 1.125 0 1 1 1.124 0Z"
    />
  </svg>
)
export default SvgComponent
