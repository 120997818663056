import React, { useState, useContext, useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'
import { postCheckoutCall, getSelectCall } from '../../../api/axios'
import useCancellablePromise from '../../../api/cancelRequest'
import { ToastContext } from '../../../context/toastContext'
import RazorpayPayment from '../../../views/payment-integration'
import { getValueFromCookie } from '../../../utils/cookies'
import { SSE_TIMEOUT } from '../../../constants/sse-waiting-time'
import { toast_actions, toast_types } from '../../../components/shared/toast/utils/toast'
import { ButtonWrapper, LoaderWrapper } from '../../../styles/orderModal'
import CircularProgress from '@mui/material/CircularProgress'
import { useHistory } from 'react-router-dom'
import CustomButton from 'components/customButton'

const PlaceOrder = ({ product, productAmount, placeOrderEnable, onClose }) => {
  const [apiCallSuccess, setApiCallSuccess] = useState(false)
  const [raorpayPaymentId, setRazorpayPaymentId] = useState('')
  const [confirmLoading, setConfirmLoading] = useState('')

  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])

  const history = useHistory()
  const [orderId, setOrderId] = useState('')
  const dispatch = useContext(ToastContext)
  const { cancellablePromise } = useCancellablePromise()

  const razorPayOrder = async () => {
    ReactGA.event({
      category: 'Orders',
      action: 'Click',
      label: 'Place Order',
    })

    const payload = {
      amount: parseFloat(productAmount).toFixed(2),
      currency: 'INR',
    }

    try {
      const orderData = await cancellablePromise(postCheckoutCall(`/clientApis/v2/razorpay/createOrder`, payload))
      setOrderId(orderData?.data?.id)
      setApiCallSuccess(!apiCallSuccess)
      // setApiCallSuccess(true)
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Transaction Failed',
        },
      })
      onClose()
    }
  }

  // useEffect(() => {
  //   if (apiCallSuccess) {
  //     razorPayOrder()
  //   }
  // }, [apiCallSuccess])

  const handlePaymentSuccess = (razorpay_payment_id) => {
    setRazorpayPaymentId(razorpay_payment_id)
  }

  useEffect(() => {
    if (raorpayPaymentId) {
      setConfirmLoading(true)
      confirmOrder()
    }
  }, [raorpayPaymentId])

  const confirmOrder = async () => {
    responseRef.current = []
    const parentOrderIDMap = new Map(JSON.parse(getValueFromCookie('parent_and_transaction_id_map')))
    const {
      productQuotes: productQuotesForCheckout,
      // payment: payment
    } = JSON.parse(localStorage.getItem('checkout_details') || '{}')
    const shippingAddress = JSON.parse(getValueFromCookie('delivery_address'))
    const updatedCartItem = JSON.parse(localStorage.getItem('updatedCartItems'))
    try {
      const payload = [
        {
          context: {
            domain: product?.context?.domain,
            city: shippingAddress?.address.areaCode,
            parent_order_id: parentOrderIDMap.get(product?.provider_details?.id).parent_order_id,
            transaction_id: parentOrderIDMap.get(product?.provider_details?.id).transaction_id,
          },
          message: {
            payment: {
              paid_amount: Number(productQuotesForCheckout?.[0]?.price?.value),
              type: 'ON-ORDER',
              razorpayPaymentId: raorpayPaymentId,
              '@ondc/org/settlement_basis': updatedCartItem.payment?.['@ondc/org/settlement_basis'] ?? null,
              '@ondc/org/settlement_window': updatedCartItem.payment?.['@ondc/org/settlement_window'] ?? null,
              '@ondc/org/withholding_amount': updatedCartItem.payment?.['@ondc/org/withholding_amount'] ?? null,
            },

            providers: {
              id: product?.provider_details?.local_id,
              locations: [product?.location_details?.local_id],
            },
          },
        },
      ]

      const data = await cancellablePromise(postCheckoutCall('clientApis/v2/confirm_order', payload))
      //Error handling workflow eg, NACK
      // const isNACK = data.find(
      // (item) => item.error && item.message.ack.status === "NACK"
      // );
      const isNACK = data.find((item) => item.error && item.code !== '')
      if (isNACK) {
        // dispatchError(isNACK.error.message)
        // setConfirmOrderLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.warning,
            message:
              'The Seller is not reachable to accept the orders. Please try again later. Thank you for being patience.',
          },
        })
        onClose()
      } else {
        onConfirm(
          data?.map((txn) => {
            const { context } = txn
            return context?.message_id
          }),
        )
      }
    } catch (err) {
      //need to show error from backend
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while confirming your order. Please bear with us & get back to us sometime.',
        },
      })
      onClose()
      history.push('/')
      // dispatchError(err?.response?.data?.error?.message)
      //   setConfirmOrderLoading(false)
    }
  }

  // use this function to confirm the order
  function onConfirm(message_id) {
    eventTimeOutRef.current = []
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    message_id.forEach((id) => {
      let es = new window.EventSourcePolyfill(
        `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
        header,
      )

      es.addEventListener('on_confirm', (e) => {
        const { messageId } = JSON.parse(e.data)
        onConfirmOrder(messageId)

        // Close the event connection as soon as the response is received
        es.close()
        const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
        if (eventTimeout) {
          clearTimeout(eventTimeout.timer)
          eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
        }
      })

      const timer = setTimeout(() => {
        eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
          eventSource.close()
          clearTimeout(timer)
        })

        // check if all the orders got canceled
        if (responseRef.current.length <= 0) {
          //   setConfirmOrderLoading(false);
          onClose()
          history.push('/')
          return 'The seller is not reachable at the moment. Please try again later. Thank you for your patience.'
        }
      }, SSE_TIMEOUT)

      eventTimeOutRef.current = [
        ...eventTimeOutRef.current,
        {
          eventSource: es,
          timer,
        },
      ]
    })
  }

  // on confirm order Api
  const onConfirmOrder = async (message_id) => {
    try {
      const data = await cancellablePromise(getSelectCall(`clientApis/v2/on_confirm_order?messageIds=${message_id}`))
      responseRef.current = [...responseRef.current, data[0]]

      if (data) {
        setConfirmLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Order placed Successfully.',
          },
        })
        onClose()
        history.push('/profile')
      }
    } catch (err) {
      setConfirmLoading(false)
      history.push('/')
    }
  }

  return (
    <>
      <ButtonWrapper>
        <CustomButton
          label={'Place Order'}
          variant={'contained1'}
          onClick={razorPayOrder}
          disabled={!placeOrderEnable}
          className="address-button"
        />
      </ButtonWrapper>
      {confirmLoading && (
        <LoaderWrapper>
          <CircularProgress indicator={<CircularProgress style={{ fontSize: 55 }} />} />
        </LoaderWrapper>
      )}
      <RazorpayPayment
        onApiSuccess={apiCallSuccess}
        orderId={orderId}
        amount={parseInt(productAmount).toFixed(2)}
        setRazorpayPaymentId={handlePaymentSuccess}
      />
    </>
  )
}

export default PlaceOrder
