import { useEffect } from 'react'
import { theme } from 'styles/Theme/theme'
import Cookies from 'js-cookie'
import Logo from 'assets/images/HpLogo.png'

const RazorpayPayment = ({ onApiSuccess, orderId, amount, setRazorpayPaymentId }) => {
  const user = Cookies.get('user')
  const parsedUser = user ? JSON.parse(user) : null
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }

      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: amount,
      currency: 'INR',
      name: 'Himira',
      description: 'Test Transaction',
      image: Logo,
      order_id: orderId,
      config: {
        display: {
          blocks: {
            utib: {
              instruments: [
                {
                  method: 'upi',
                  apps: ['google_pay', 'phonepe'],
                  flows: ['qr'],
                },
              ],
            },
          },
          sequence: ['block.utib', 'block.other'],
          preferences: {
            show_default_blocks: true, // Should Checkout show its default blocks?
          },
        },
      },

      handler: (res) => {
        if (res?.razorpay_payment_id) {
          setRazorpayPaymentId(res?.razorpay_payment_id)
        }
      },

      prefill: {
        name: parsedUser?.name,
        email: parsedUser?.email,
        contact: parsedUser?.phone,
      },
      notes: {
        address: 'Himira',
      },
      theme: {
        color: `${theme.HPSECONDARYCOLOR}`,
      },
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  useEffect(() => {
    if (onApiSuccess) {
      displayRazorpay()
    }
  }, [onApiSuccess])

  return null
}

export default RazorpayPayment
