import { makeStyles } from '@mui/styles';
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
    paymentCard: {
        backgroundColor: `${theme.PAYMENTBACKGROUNDCOLOR} !important`,
        boxShadow: 'none !important',
        border: `1px solid ${theme.GREYCOLORDARK} !important`,
        cursor: 'pointer',
        '&$activeCard': {
            border: `1px solid ${theme.PRIMARYCOLOR} !important`,
        },
        position: 'relative',
        // textAlign: 'center !important'
    },
    activeCard: {},
    paymentImage: {
        width: '100% !important',
        height: '326px !important',
        "& rect": {
            width: '100% !important',
            height: '100% !important',
            strokeOpacity: '0',
            fill: `${theme.PAYMENTBACKGROUNDCOLOR} !important`
        }
    },
    paymentTypo: {
        fontSize: '16px !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important',
        textAlign: 'center',
        marginTop: '12px !important'
    },
    checkedIcon: {
        position: 'absolute',
        top: '8px',
        right: '8px',
    },
    nonClickable: {
        cursor: "not-allowed !important"
    }
});

export default useStyles;