import React from 'react'
import { CatelogueIconButton } from '../../../../product/subCategories/style'
import PreviousIcon from '../../../../../assets/svg/LeftArrow'
import { useHistory } from 'react-router-dom'
// import './navbar.css'

export default function Navbar() {
  const history = useHistory()
  return (
    <div className="absolute w-full border-t-4 border-primary bg-white ">
      <div className="border-b border-gray-300">
        <div className="flex max-w-6xl mx-auto">
          <CatelogueIconButton
            onClick={() => {
              history.push('/')
            }}
          >
            <PreviousIcon />
          </CatelogueIconButton>
          <span className="py-3 px-3 text-2xl font-semibold text-primary">Test your catalogue</span>
        </div>
      </div>
    </div>
  )
}
