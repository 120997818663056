import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 180px 100px 0 100px;
  .MuiTabPanel-root {
    padding: 24px 0;
  }
  @media (max-width: 450px) {
    gap: 30px;
    margin: 75px 16px 16px 16px;
  }
`
export const MainHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 35.2px;
  }
`
