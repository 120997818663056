import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import useCancellablePromise from 'api/cancelRequest'
import { getAllBrandsRequest } from 'api/brand.api'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { search_types } from 'constants/searchTypes'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import Loading from 'components/shared/loading/loading'
import { useTheme } from 'context/newThemeContext'
import { useThemeContext } from 'context/themeContext'
import { SearchContext } from 'context/searchContext'
import ProviderImage from 'assets/images/no_image_found.png'
import PreviousIcon from 'assets/svg/LeftArrow'
import NextIcon from 'assets/svg/RightArrow'
import { IconButton, HeadingWrapper, ViewAllText } from 'components/product/subCategories/style'
import useStyles, { TopBrandsContainer, ProvidersCard } from 'components/home/topBrands/style'
import { TitleText } from 'styles/home'

const BrandCard = ({ data, index, onMouseOver }) => {
  const classes = useStyles()
  const history = useHistory()
  const locationData = useLocation()
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  const { id, descriptor } = data
  const { name: brand_name, symbol } = descriptor

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  const handleClick = (id) => {
    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    history.push(`/brand?brandId=${id}`)
  }

  return (
    <>
      <Tooltip title={brand_name}>
        <ProvidersCard onMouseOver={onMouseOver} onClick={() => handleClick(id)}>
          <img
            className={classes.brandImage}
            src={symbol ? symbol : ProviderImage}
            alt={`brand-${index}`}
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ProvidersCard>
      </Tooltip>
    </>
  )
}

const TopBrands = () => {
  const classes = useStyles()
  const [activeBrandIndex, setActiveBrandIndex] = useState(1)
  activeBrandIndex
  const [brands, setBrands] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isReverse, setIsReverse] = useState(false)
  const dispatch = useContext(ToastContext)
  const { mode } = useThemeContext()
  const history = useHistory()

  const toggleDirection = () => {
    setIsReverse((prevState) => !prevState)
  }

  function handleResize() {
    const width = window.innerWidth
    setScreenWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const getAllBrands = async () => {
    setIsLoading(true)
    try {
      const data = await cancellablePromise(getAllBrandsRequest())
      setBrands(data?.data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllBrands()
  }, [])

  const rowsPerPage = parseInt(screenWidth / 120) - 7
  const totalPageCount = Math.ceil(brands?.length / rowsPerPage)
  const { theme } = useTheme()

  const handleAllProviders = () => {
    history.push('/providers')
  }

  return (
    <TopBrandsContainer theme={theme} color={mode}>
      <Grid container spacing={3} className={classes.topBrandsContainer}>
        <HeadingWrapper>
          <TitleText>All Providers</TitleText>
          <ViewAllText onClick={handleAllProviders}>View All </ViewAllText>
        </HeadingWrapper>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Grid item md={12} lg={12} xl={12} className={classes.brandsContainer}>
              <div style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                <IconButton
                  color="inherit"
                  className={classes.actionButton}
                  onClick={() => {
                    setPage(page - 1)
                    toggleDirection()
                  }}
                  disabled={page === 0}
                >
                  <PreviousIcon />
                </IconButton>
              </div>
              <Marquee direction={isReverse ? 'right' : 'left'}>
                {brands?.map((brand, brandIndex) => (
                  <BrandCard
                    key={`btand-index-${brandIndex}`}
                    data={brand}
                    index={brandIndex}
                    // isActive={brandIndex === activeBrandIndex}
                    onMouseOver={() => {
                      setActiveBrandIndex(brandIndex)
                    }}
                  />
                ))}
              </Marquee>
              <div style={{ marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                <IconButton
                  color="inherit"
                  className={classes.actionButton}
                  onClick={() => {
                    setPage(page + 1)
                    toggleDirection()
                  }}
                  disabled={page === totalPageCount - 1}
                >
                  <NextIcon />
                </IconButton>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </TopBrandsContainer>
  )
}

export default TopBrands
