import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import SmartWatch from '../../assets/images/dashboardProducts/smart-watch.png'
import LatestLaptops from '../../assets/images/dashboardProducts/latest-laptop.png'
import Cameras from '../../assets/images/dashboardProducts/cameraWidget.png'
import Phones from '../../assets/images/dashboardProducts/mobilePhones.png'
import Headphones from '../../assets/images/dashboardProducts/headphonesWidget.png'
import TopBrands from './topBrands/topBrands'
import { useThemeContext } from '../../context/themeContext'
import TopSellingProducts from '../../views/TopSelling'
import {
  HomePageContainer,
  DashboardWrapper,
  NewDashboardWrapper,
  FirstSection,
  // InsideContent,
  // FashionText,
  // ShopNowButton,
  SecondSection,
  ThirdSection,
  View,
  // RightFirstSection,
  // GrocerySection,
  // GroceryContent,
  // RightSecondSection,
  // ElectronicContent,
  MobileDashboardWrapper,
  NewMobileDashboardWrapper,
  BackgroundImageSection,
  // InterestedContainer,
  // InterestedContent,
  TitleText,
  TitleWrapper,
} from '../../styles/home'
import { useTheme } from '../../context/newThemeContext'
import { AddCookie, getValueFromCookie } from '../../utils/cookies'
import { search_types } from '../../constants/searchTypes'
import { SearchContext } from '../../context/searchContext'
import ElectronicBrands from '../category/brands/electronicBrands'
import ModalComponent from '../common/Modal'
import PlaceOrderModal from '../../views/OrderModal'
// import { data } from 'jquery'

const Home = () => {
  const history = useHistory()
  const locationData = useLocation()
  const { setSearchData } = useContext(SearchContext)
  const { mode } = useThemeContext()
  const { theme } = useTheme()
  const [orderModal, setOrderModal] = useState(false)

  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  useEffect(() => {
    let search_context = getValueFromCookie('search_context')
    setSearchedLocation(search_context?.location)
  }, [])

  // const updateQueryParams = (catName) => {
  //   const params = new URLSearchParams({})
  //   params.set('c', catName)
  //   if (locationData.search === '' && query.get('c') === null) {
  //     history.push({ pathname: locationData.pathname, search: params.toString() })
  //   }
  // }

  const handleclicksearch = (value) => {
    let searchDataUpdate = {
      type: search_types.PRODUCT,
      value: value.replace('.', ''),
    }

    setSearchData(searchDataUpdate)
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))

    updateSearchParams(searchDataUpdate)
  }

  const handleAllStores = () => {
    history.push('/stores')
  }

  const updateSearchParams = (searchDataUpdate) => {
    const categoryName = query.get('c')
    const subCategoryName = query.get('sc')
    const params = new URLSearchParams({})

    if (locationData.pathname !== '/products') {
      history.push(`/products?s=${searchDataUpdate.value}`)
    } else {
      if (searchDataUpdate.value) {
        params.set('s', searchDataUpdate.value)
      }

      if (categoryName) {
        params.set('c', categoryName)
      }

      if (subCategoryName) {
        params.set('sc', subCategoryName)
      }

      history.replace({ pathname: locationData.pathname, search: params.toString(), state: searchDataUpdate.value })
    }
  }

  var settings = {
    dots: false,
    arrows: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    // slidesToScroll: 1,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  return (
    <>
      <HomePageContainer color={mode} theme={theme}>
        <DashboardWrapper theme={theme}>
          <FirstSection onClick={() => handleclicksearch('watch')}>
            <img
              src={SmartWatch}
              alt="watchicon"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </FirstSection>
          <SecondSection onClick={() => handleclicksearch('laptop')}>
            <img
              src={LatestLaptops}
              alt="laptops"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </SecondSection>
        </DashboardWrapper>
        <MobileDashboardWrapper>
          <Slider {...settings}>
            <BackgroundImageSection onClick={() => handleclicksearch('watch')}>
              <img
                src={SmartWatch}
                alt="watchicon"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch('laptop')}>
              <img
                src={LatestLaptops}
                alt="watchicon"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
          </Slider>
        </MobileDashboardWrapper>
        <TopBrands />
        <TitleWrapper>
          <TitleText theme={theme}>Stores</TitleText>
          <View onClick={handleAllStores}>View All</View>
        </TitleWrapper>
        <ElectronicBrands />
        <NewDashboardWrapper theme={theme}>
          <FirstSection onClick={() => handleclicksearch('Mobile Phone')}>
            <img
              src={Phones}
              alt="phonesIcon"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </FirstSection>
          <SecondSection onClick={() => handleclicksearch(`Headset`)}>
            <img
              src={Headphones}
              alt="headphonesicon"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </SecondSection>
          <ThirdSection onClick={() => handleclicksearch(`camera`)}>
            <img
              src={Cameras}
              alt="cameraIcon"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </ThirdSection>
        </NewDashboardWrapper>
        <NewMobileDashboardWrapper>
          <Slider {...settings}>
            <BackgroundImageSection onClick={() => handleclicksearch('Mobile Phone')}>
              <img
                src={Phones}
                alt="phonesIcon"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch('Headset')}>
              <img
                src={Headphones}
                alt="headphonesicon"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
            <BackgroundImageSection onClick={() => handleclicksearch('camera')}>
              <img
                src={Cameras}
                alt="cameraIcon"
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            </BackgroundImageSection>
          </Slider>
        </NewMobileDashboardWrapper>
        <TopSellingProducts />
      </HomePageContainer>
      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal onClose={() => setOrderModal(false)} />
        </ModalComponent>
      )}
    </>
  )
}

export default Home
